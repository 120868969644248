import React from "react";
import "./SliderWrapper.scss";

const SliderWrapper = ({ children, categorySection }) => (
    <div className=" d-flex slider-category-conten-wrapper">
      <div
        className="slider-category-conten-img"
        style={
          {
            backgroundImage: `url(${categorySection.category_picture})`,
          }
        }
      />
      <div className="container-slider-category-conten">
        <div className="slider-category-conten">
          <div>
            {
              categorySection.category
            }
          </div>
          <p>
          {
            categorySection.description
          }
          </p>
        </div>
        <div className="slider-category-wrapper">{children}</div>
      </div>
    </div>
);

export default SliderWrapper;
