import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./ContentStoreModal.scss";
import { FacebookShareButton, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, FacebookIcon, } from "react-share";

class ContentStoreModal extends Component {
  state = {
    isCopy: false,
  };
  copyLinkArt() {
    navigator.clipboard.writeText(window.location.href);
    this.setState({ isCopy: true });
    setTimeout(() => {
      this.setState({ isCopy: false });
    }, 1000);
  }
  async openInstagram(link){
    await this.copyLinkArt(link)
    var win = window.open('instagram://story-camera');
  }
  render(props) {
    const listButtonStore = [
      {
        image: process.env.PUBLIC_URL + "/assets/img/tokopedia.png",
        link: this.props.data.link_tokopedia,
      },
      {
        image: process.env.PUBLIC_URL + "/assets/img/shopee.png",
        link: this.props.data.link_shopee,
      },
      {
        image: process.env.PUBLIC_URL + "/assets/img/bukalapak2.png",
        link: this.props.data.link_bukalapak,
      },
      {
        image: process.env.PUBLIC_URL + "/assets/img/lazada.png",
        link: this.props.data.link_lazada,
      },
    ];
    const { isCopy } = this.state;
    return (
      <Modal
        className="modal-storeprefix-wrapper"
        show={this.props.show}
        onHide={() => this.props.onHide(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {this.props.data ? (
            <section className="wrapper-storeprefix">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-image-storeprefix">
                    <img
                      src={this.props.data.product_image}
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                      }}
                      alt={this.props.data.product_name}
                    />
                    {/* <div className="icon-image-storeprefix-zoom">
                        <i className="fa fa-search" />
                      </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-conten-storeprefix">
                    <div className="title-wrapper-storeprefix">
                      {this.props.data.product_name}
                    </div>

                    <div className="wrapper-flex-storeprefix-button-text">
                      <div className="item-desktop">
                        <div className="d-flex justify-item-between">
                          <WhatsappShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('merch//', 'merch/')}
                            quote={this.props.data.product_name + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <WhatsappIcon size={40} round={true} />
                            </a>
                          </WhatsappShareButton>
                          <FacebookShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('merch//', 'merch/')}
                            quote={this.props.data.product_name + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <FacebookIcon size={40} round={true} />
                            </a>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('merch//', 'merch/')}
                            quote={this.props.data.product_name + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <TwitterIcon size={40} round={true} />
                            </a>
                          </TwitterShareButton>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.openInstagram(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                              {/* <div className="child-text-button-art">SHARE</div> */}
                            </div>
                          </div>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.copyLinkArt(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                              {/* <div className="child-text-button-art">SHARE</div> */}
                            </div>
                          </div>
                        </div>

                      </div>
                      {/* <div className="date-storeprefix-button" /> */}
                      <div className="item-mobile ">


                        <div className="d-flex">


                          <WhatsappShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('merch//', 'merch/')}
                            quote={this.props.data.product_name + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <WhatsappIcon size={30} round={true} />
                            </a>
                          </WhatsappShareButton>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.openInstagram(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                              {/* <div className="child-text-button-art">SHARE</div> */}
                            </div>
                          </div>
                          <FacebookShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('merch//', 'merch/')}
                            quote={this.props.data.product_name + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <FacebookIcon size={30} round={true} />
                            </a>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('merch//', 'merch/')}
                            quote={this.props.data.product_name + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <TwitterIcon size={30} round={true} />
                            </a>
                          </TwitterShareButton>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.copyLinkArt(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="desc-wrapper-storeprefix">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.data.description,
                        }}
                      />
                    </div>
                    <div>
                      <div className="modal-storeprefix-title-buy">
                        Beli {this.props.data.product_name}
                      </div>
                      <div className="container-modal-buttons-detail-storeprefix row">
                        {listButtonStore.map((key, index) => (
                          <div className="col-xl-6 col-lg-6 col-md-12">
                            {key.link ? (
                              <a
                                href={key.link}
                                target="_blank"
                                rel="noreferrer"
                                key={index}
                                className="button-modal-storeprefix"
                              >
                                <img alt={{ index }} src={key.image} />
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {isCopy && <div className="modal-copied">Link Copied</div>}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ContentStoreModal;
