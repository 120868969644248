import React, { Component } from "react";
import { Link,Redirect   } from "react-router-dom";
import Helper from "../Helper/helper";
import { withToastManager } from "react-toast-notifications";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import { translate } from "react-multi-lang";
import Axios from "axios";
import "./SubmitC2.scss";
import { useDropzone } from 'react-dropzone';
import DropzoneComponent from './DropzoneComponent';
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";

class ArtModal extends Helper {  

  constructor(props) {
    super(props);
    this.state = {
      file: '',
      name: '',
      description: '',
      link_instagram: '',
      image: '',
      category: '',
      data: {
        name: "",
        image: "",
        description: "",
        link_instagram: "",
        category: "F&B"
      },
    }
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeInstagram = this.handleChangeInstagram.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
  }

    
    

    handleCallback = (childData) =>{
     this.setState({ image: childData})
  }

  componentDidMount() {
    // if (this.props.location.state) {
    //   //
    // } else {
    //   window.location = "/";
    // }
  }

  handleChangeName(event) {    
    this.setState({name: event.target.value});  
  }

  handleChangeDescription(event) {    
    this.setState({description: event.target.value});  
  }

  handleChangeCategory(event) {    
    this.setState({category: event.target.value});  
  }

  handleChangeInstagram(event) {    
    this.setState({link_instagram: event.target.value});  
  }
  // handleChange(event) {    this.setState({value: event.target.value});  }
  handleSubmit = (event) => {
    event.preventDefault();
    // const { toastManager } = this.props;
   
    api
      .postMethod("umkm/add_data", {
        name: this.state.name,
        description: this.state.description,
        link_instagram: this.state.link_instagram,
        image: this.state.image,
        category: this.state.category
      })
      .then((response) => {
        if (response.data.success) {
          alert('Data berhasil dikirim!')
          this.props.history.push("/promoc2");
        } else {
          alert(response.data.error_messages)
            // console.log(response.data.messages)
          
        }
      })
      .catch((error) => {
        console.log(error)
        
      });
  };

  redirect(){
    return <Redirect to="/promoc2" />
  }
  render() {
    const { isCopy, listMoreLike } = this.state;
    var inputStyle = {
      color: 'white',
      borderColor:'white'
    }
    const { data } = this.state;
    return (
      <>
      <div>
        <div className="" >
        

          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                <div className="d-flex w-100 auth-page-header mb-4 w-100 d-flex align-items-center justify-content-center">
                  <Link className="d-flex align-items-center justify-content-center" to={"/"}>
                    <img
                      src={process.env.PUBLIC_URL + '/assets/img/logo-vindes.png'}
                      className="submit-art-site-logo-vindes"
                      alt="logo_img"
                    />
                  </Link>
                </div>
                {/* <h3 className="register-box-head">MASUK</h3> */}
                <p class="align-center">Upload Promo C2</p>

                <p class="align-center text-center">
                  Unggah foto Anda. Ini juga akan digunakan sebagai thumbnail di feed
                </p>
                <form onSubmit={this.handleSubmit} className="auth-form login-new-form">

                  <DropzoneComponent parentCallback = {this.handleCallback} />
                  <div className="mt-2 form-group">
                    <label htmlFor="email" className="text-white">Nama Bisnis</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={this.state.name} onChange={this.handleChangeName}
                      style={inputStyle}
                    />
                  </div>
                  <div className="mt-2 form-group">
                    <label htmlFor="email" className="text-white">Link Instagram</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={this.state.link_instagram} onChange={this.handleChangeInstagram}
                     
                      style={inputStyle}
                    />
                  </div>

                  <div className="mt-2 form-group">
                    <label htmlFor="email" className="text-white">Kategori</label>
                    <select type="text"
                      className="form-control"
                      id="type"
                      name="type"
                      value={this.state.category} onChange={this.handleChangeCategory}
                      style={inputStyle}>
                      <option class="text-option-dark" value="F&B">F&B</option>
                      <option class="text-option-dark" value="Electronic">Electronic</option>
                      <option class="text-option-dark" value="Service">Service</option>
                      <option class="text-option-dark" value="Accessories">Accessories</option>
                      <option class="text-option-dark" value="Fashion & Lifestyle">Fashion & Lifestyle</option>
                      <option class="text-option-dark" value="Furniture">Furniture</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd" className="text-white">Deskripsi</label>
                    <textarea
                      type="textarea"
                      rows="4"
                      
                      className="form-control input-deskripsi-produk"
                      id="description"
                      name="description"
                      onChange={this.handleChangeDescription}
                      value={this.state.description}
                      style={inputStyle}
                    />
                  </div>
                  <button
                    className="btn auth-btn btn-login"
                    id={"submit-promoc2"}
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default ArtModal;
