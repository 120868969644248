import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import api from "../../Environment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./PromoSlider.css"

// import "./LatestLandingPage.css";
const $ = window.$;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="next-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="prev-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-left"></i>
    </div>
  );
}




class PromoSlider extends Component {
  constructor(props) {
    super(props);
}
  state = {
    isAuthenticated: this.props.data,
    footer_pages1: [],
    
    footer_pages2: [],
    loading: true,
    footerList: null,
    listCategoryAndVideo: []
  };

  componentDidMount() {
    this.fecthApiListHome()
  }

  renderCategories = (videos)=>{
    var indents = [];
    for (let i = 0; i <= 2; i++) {
      let index = 0
      // indents.push(<div className="categories dummies"  >{ videos.videos[index].category_name}</div>)
      // indents.push(<div className="categories dummies"  >{videos}</div>);
    }
    return indents;
     
      
    
    
  }

  async fecthApiListHome() {
    await api
      .postMethod("categories_home")
      .then(async (response) => {
        // console.log('inilah dia:'+ response.data.data)
        this.setState({
          listCategoryAndVideo: await response.data.data,
        });
      })
      .catch(function(error) {});
  }

  renderTags(category){
    if(category.tags){
      return (category.tags.map((tag, i)=>(
        <span class="badge badge-pill badge-promo">#{tag}</span>
     )))
    }
  }

 
  render() {
    // console.log('testprops:'+this.props.items[0].category)
    let items = ['',''];
    items = this.props.items
    // console.log('testprops'+Array.isArray(items))
    const settings = {
      dots: true,
      infinite: true,
      autoplay: false,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    const settingsMobile = {
      dots: true,
      infinite: true,
      autoplay: false,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    return (
      <>
      <div className="landing-slider-desktop-wrapper">
        <Slider  {...settings}>
        {
        items.map((category, i) => (
          <Link to={{
            pathname:"/promoc2",
            creation: category
        }} >
            <div  className="list-container-promo-slider">
              <div  key={`${i}`} className="item-promo-slider" style={{
                    backgroundImage: `url(${category.image})`,
                  }}>
              </div>
              
            </div>
           
            <div className="container-badge">
            <div className="title-promo-list">{category.name}</div>
              <div className="container-inside">
              {
                // category.tags.map((tag, i)=>(
                //    <span class="badge badge-pill badge-promo">#{tag}</span>
                // ))
                this.renderTags(category)
              }
              </div>
              
            </div>
            
            </Link>
            ))
          }
        </Slider>
      </div>

      <div className="landing-slider-mobile-wrapper">
        <Slider  {...settingsMobile}>
        {
        items.map((category, i) => (
          
          <Link to={{
            pathname:"/promoc2",
            creation: category
        }} className="container-promo-slider">
            <div  className="list-container-promo-slider">
              <div  key={`${i}`} className="item-promo-slider" style={{
                    backgroundImage: `url(${category.image})`,
                  }}>
              </div>
              
            </div>
           
            <div className="container-badge">
            <p className="title-promo-list">{category.name}</p>
              <div className="container-inside">
              {
                // category.tags.map((tag, i)=>(
                //    <span class="badge badge-pill badge-promo">#{tag}</span>
                // ))
                this.renderTags(category)
              }
              </div>
              
            </div>
            
            </Link>
           
            ))
          }
        </Slider>
      </div>
      </>
    );
  }
}

export default PromoSlider;
