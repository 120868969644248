import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import './ContentPromoC2Modal.scss';
import {FacebookShareButton, TwitterShareButton,  TwitterIcon,   WhatsappShareButton, WhatsappIcon, FacebookIcon,} from "react-share";

class ContentPromoC2Modal extends Component {

  state = {
    isCopy: false,
  }
  
  copyLinkPromo(link) {
    navigator.clipboard.writeText((window.location.href+"/"+link).replace('promoc2//','promoc2/'));
    this.setState({ isCopy: true });
    setTimeout(() => {
      this.setState({ isCopy: false });
    }, 1000);
  }

  async openInstagram(link){
    await this.copyLinkPromo(link)
    var win = window.open('instagram://story-camera');
  }

  
  async openLinkInstagram(link){

    if(!link.includes('http') ){
     
      link = 'https://' + link
    }

    var win = window.open(link);
  }
    render(props) {
      const { isCopy } = this.state;
      const dataProps = this.props.dataPropsDetailModalC2
   

        return (
            <Modal className="modal-promoc2-wrapper" show={this.props.show}
            onHide={() => this.props.onHide(false)}>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                {this.props.dataPropsDetailModalC2 ? (
            <section className="wrapper-promoc2">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="wrapper-image-promoc2">
                      <img
                        src={this.props.dataPropsDetailModalC2.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                        }}
                        alt={this.props.dataPropsDetailModalC2.name}
                      />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-conten-promoc2">
                    <div className="title-wrapper-promoc2">
                      {this.props.dataPropsDetailModalC2.name}
                    </div>

                    <div className="desc-wrapper-promoc2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.dataPropsDetailModalC2
                            .description,
                        }}
                      />

                      <h5 className="mt-5">Follow {this.props.dataPropsDetailModalC2.name} </h5>

                      <div
                          className="wrapper-button-promoc2-share mr-2"
                          // onClick={() => this.openInstagram(dataProps.id)}
                          onClick={() => this.openLinkInstagram(this.props.dataPropsDetailModalC2.link_instagram)}
                        >
                          {/* <a target="_blank" href={this.props.dataPropsDetailModalC2.link_instagram}> */}
                            <div className="container-button-promoc2">
                              <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                              {/* <div className="child-text-button-art">SHARE</div> */}
                            </div>
                          {/* </a> */}
                      </div>
                    </div>
                    <div className="d-flex">
                    <WhatsappShareButton
                          url={(window.location.href+'/'+dataProps.id).replace('promoc2//','promoc2/')}
                          quote={dataProps.name +" - "+dataProps
                          .description}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <WhatsappIcon size={40} round={true} />
                          </a>
                        </WhatsappShareButton>
                        <div
                          className="wrapper-button-promoc2-share mr-2"
                          onClick={() => this.openInstagram(dataProps.id)}
                        >
                          <div className="container-button-promoc2">
                            <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                            {/* <div className="child-text-button-art">SHARE</div> */}
                          </div>
                        </div>
                        <FacebookShareButton
                          url={(window.location.href+'/'+dataProps).replace('promoc2//','promoc2/')}
                          quote={dataProps.name +" - "}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <FacebookIcon size={40} round={true} />
                          </a>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={(window.location.href+'/'+dataProps.id).replace('promoc2//','promoc2/')}
                          quote={dataProps.name +" - "}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <TwitterIcon size={40} round={true} />
                          </a>
                        </TwitterShareButton>
                        <div
                          className="wrapper-button-promoc2-share mr-2"
                          onClick={() => this.copyLinkPromo(dataProps.id)}
                        >
                          <div className="container-button-promoc2">
                            <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                            {/* <div className="child-text-button-art">SHARE</div> */}
                          </div>
                        </div>
                        </div>
                    
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {isCopy && <div className="modal-copied">Link Copied</div>}
                </Modal.Body>
            </Modal>
        )
    };
}

export default ContentPromoC2Modal;