import React, { Component } from "react";

import { apiConstants } from "../Constant/constants";
import api from "../../Environment";
import TesCom from "./TesCom";
import Navbar from "../Navbar/Navbar";
import ContentStoreModal from "../Modals/ContentStoreModal/ContentStoreModal";
import UserHeader from "../Layouts/SubLayout/UserHeader";
import SliderVindes from "../Slider/SliderVindes";
// new
import AOS from "aos";
import "aos/dist/aos.css";
// style
import "./Toko.scoped.css";
const images = [
  process.env.PUBLIC_URL + "/assets/img/PROD5.png",
  process.env.PUBLIC_URL + "/assets/img/PROD1.png",
  process.env.PUBLIC_URL + "/assets/img/PROD2.png",
  process.env.PUBLIC_URL + "/assets/img/PROD3.png",
  process.env.PUBLIC_URL + "/assets/img/PROD2.png",
  process.env.PUBLIC_URL + "/assets/img/PROD3.png",
  process.env.PUBLIC_URL + "/assets/img/PROD2.png",
  process.env.PUBLIC_URL + "/assets/img/PROD1.png",
  process.env.PUBLIC_URL + "/assets/img/PROD4.png",
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="next-arrow" onClick={onClick}>
      <img
        src={process.env.PUBLIC_URL + "/assets/img/arrow-right-black.png"}
        alt=""
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

class LatestLandingPage extends Component {
  state = {
    HomeSettings: [],
    loading: true,
    loadingData: true,
    faqData: null,
    videoList: null,
    loading: true,
    skipCount: 0,
    loadMoreButtonDisable: false,
    loadingContent: null,
    mainData: null,
    isHidden: "hidden",
    isActive: "",
    showNavbar: false,
    stores: [''],
    count_stores: 0,
    pageNumber: 1
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      body: "",
      data: [],
      isCopy: false,
      slider: [],
    };
  }

 

  
  handleClose = (fromModal) => {
    this.setState({
      show: fromModal,
    });
  };

  hideBar = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 10) {
      this.setState({ showNavbar: "shows" });
    } else {
      this.setState({ showNavbar: "hide" });
    }
  };

  async componentDidMount() {
    await this.setState({pageNumber: 0})
    
    await window.scrollTo(0, 0);
  

    await this.getStores();
    let inputData;
    let apiURL;
    // await this.viewAllApiCall(inputData, apiURL);
    await AOS.init();
    this.showDetailLink();
    this.getSlider();
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
        this.loadMore()
      }
    }

  async getSlider() {
    await api
      .postMethod("sliders/list", { page: "Merch" })
      .then(async (response) => {
        let arrayImages = [];

        await this.setState({
          slider: response.data.data,
          loadingProgram: await false,
        });
        // console.log("inilah dia slider:" + response.data.data);
      })
      .catch(function(error) {});
  }

  async componentDidUpdate() {
    await AOS.refresh();
  }

  async componentWillUnmount() {
    await window.removeEventListener("scroll", this.hideBar);
  }

 

  

  chunkArray(myArray, chunk_size) {
    let results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.homeSettingsUrl);
    const homeResonse = await response.json();

    this.setState({
      loading: false,
      HomeSettings: homeResonse.data,
    });
  }

  async getFaqs() {
    api.getMethod("faqs/list").then((response) => {
      if (response.data.success) {
        this.setState({
          faqData: response.data.data,
          loadingData: false,
        });
      } else {
      }
    });
  }

  async getStores() {
    
    api.postMethod("stores/v2/list", { page: this.state.pageNumber}).then(async (response) => {
      if (response.data.success === true) {
        let count_stores = response.data.data.length;

        this.setState({ 
          count_stores: count_stores, 
          stores: response.data.data,
          pageNumber: this.state.pageNumber+1
        })
      } else {
        let errorHandle = 1;
        this.setState({ errorHandle });
      }
    })
    .catch(function(error) {});
  }

  async loadMore(){
      api.postMethod("stores/v2/list", { page: this.state.pageNumber + 1}).then(async (response) => {
        if (response.data.success === true) {
          let count_stores = response.data.data.length;
  
          this.setState({ 
            count_stores: count_stores, 
            stores: [...this.state.stores, ...response.data.data],
            pageNumber: this.state.pageNumber+1
          })
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
    }
  

  showDetailLink = () => {
    document.getElementById("header").style.zIndex = 1000;
    if (this.props.location.creation) {
      this.setState({
        show: true,
        title: "Group People",
        body: "Hi, find my group details",
        data: this.props.location.creation,
      });
    }
  };

  goDetailModalMerch = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.setState({
      show: true,
      data: self,
    });
  };

  onHideModalMerch() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ show: false });
  }

  // showModalCopyLink = ()=> {
  //   navigator.clipboard.writeText(window.location.href);
  //   this.setState({isCopy: true});
  //   setTimeout(() => {
  //     this.setState({isCopy: false});
  //   }, 1000);
  // }
  render() {
    let result = null;

    // Split in group of 3 items
    // if (this.state.loading) {
    //   return <ContentLoader />;
    // } else {
    //   result = this.chunkArray(this.state.videoList, 5);
    // }
    // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
    const { loading, HomeSettings, loadingData, faqData } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3.3,
      slidesToScroll: 2,
      nextArrow: <SampleNextArrow />,
    };

    return (
      <>
        {/* <Navbar showNavbar={this.state.showNavbar}></Navbar> */}

        <UserHeader activeNav="STORE" />
        {/* <div className="slider-toko">
          <SliderVindes items={this.state.slider} />
        </div> */}

        <ContentStoreModal
          show={this.state.show}
          data={this.state.data}
          onHide={() => this.onHideModalMerch()}
        />

        <div className="wrapper-merch-page">
          <section className="filter-container-art-section-wrapper">
            <div className="d-flex">
              <div className="mr-auto title-order-merch-select">
                {this.state.count_stores} Products
              </div>
              
            </div>
          </section>

          <div className="container-merch-desktop">
            <div className="d-flex justify-content-start flex-wrap container-products">
              {this.state.stores &&
                this.state.stores.map((store, i) => (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    className="shop-list-container"
                    key={i}
                    id={store.slug}
                    onClick={() => this.goDetailModalMerch(store)}
                  >
                    
                    {store.thumbnail_image?
                      <div className="product-list">
                        <img src={store.product_image} alt="" />
                      </div>
                      :<div className="product-list">
                        <img src={store.product_image} alt="" />
                      </div>
                    }
                    <div className="title-product">{store.product_name}</div>
                    <div className="price-product" />
                    <div className="price-disc-product" />
                    <br />
                  </div>
                ))}
            </div>
          </div>

          <div className="container-merch-mobile">
            <div className="d-flex justify-content-between flex-wrap container-products">
              {this.state.stores &&
                this.state.stores.map((store, i) => (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    className="shop-list-container"
                    key={i}
                    id={store.slug}
                    onClick={() => this.goDetailModalMerch(store)}
                  >
                    {store.thumbnail_image?
                      <div className="product-list">
                        <img src={store.product_image} alt="" />
                      </div>
                      :<div className="product-list">
                        <img src={store.product_image} alt="" />
                      </div>
                    }
                    <div className="title-product">{store.product_name}</div>
                    <div className="price-product" />
                    <div className="price-disc-product" />
                    <br />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="gap-padding-bottom" />
        {/* { this.state.isCopy && <div className="modal-copied">Link Copied</div> } */}
      </>
    );
  }
}

export default LatestLandingPage;
