import React, { Component } from "react";
import api from "../../Environment";
import HomePageBanner from "./homePageBanner";
import HomeLoader from "../Loader/HomeLoader";
import SliderVindes from "../Slider/SliderVindes";
import Slider from "../SliderView/MainSlider";
import SliderCategory from "../SliderView/MainCategorySlider";
import { translate } from "react-multi-lang";
import { Link } from "react-router-dom";
import "./Home.css";

class Home extends Component {
  state = {
    maindata: null,
    errorHandle: 0,
    loading: true,
    banner: null,
    listCategoryAndVideo: null,
    slider: []
  };
  inputData = {
    page_type: "HOME",
    
  };
  
  

  async fetchApiNewrReleaseSection() {
    await api
      .postMethod("home_new_release_section", this.inputData)
      .then(async (response) => {
        if (response.data.success === true) {
          let maindata = await response.data.data;
          let banner = await response.data.banner;
          await this.getSlider();
          await this.fecthApiListHome();
          await this.setState({
            loading: false,
            maindata: maindata,
            banner: banner,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  async fecthApiListHome() {
    await api
      .postMethod("list_home")
      .then(async (response) => {
        // console.log('listCategoryAndVideo:'+response.data.data)
        this.setState({
          listCategoryAndVideo: await response.data.data,
        });
      })
      .catch(function(error) {});
  }

  async getSlider() {
    await api
      .postMethod("sliders/list", {page: "Program"})
      .then(async (response) => {       
        await this.setState({
          slider: response.data.data,
        });
      })
      .catch(function(error) {});
  }

  renderVideoList = (maindata, index) => {
    return (
      <React.Fragment key={index}>
        <div className="main-slidersec">
          <h3 className="">{maindata.title}</h3>
          <Slider>
            {maindata.data.map((movie) => (
              <Slider.Item
                movie={movie}
                activefirstVideoDetail={maindata.data[0]}
                key={movie.admin_video_id}
                first={true}
              >
                {movie.admin_video_id}
              </Slider.Item>
            ))}
          </Slider>
        </div>
      </React.Fragment>
    );
  };

  renderCategoryAndList = (key, index) => {
    return (
      <React.Fragment key={index}>
        <section className="category-container-child" id={`PREFIXPROGRAM-${key.category_id}`}>
          
          <section
            className="slider-category-conten-wrapper-desktop">
            <SliderCategory categorySection={key}>
              {key.videos.map((video) => (
                <SliderCategory.Item movie={video} key={video.admin_video_id} />
              ))}
              <Link to={{
                pathname: "/program/view-all",
                state: {
                  url_type: 'URL_TYPE_CATEGORY',
                  url_type_id: key.category_id,
                  page_type: "Program",
                  title: key.category,
                },
              }}>
                <div className="btn-view-all" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/background-vindes-signup.png`}}>
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/arrow-right.png'}
                  className="img-arrow"
                />
                <div className="title-all-vid">All Videos</div>
                </div>
              </Link>
            </SliderCategory>
          </section>

          <section className="slider-category-conten-wrapper-mobile">
            <div
              style={{
                margin: "0px 4%",
              }}
            >
              <div class="row">
                <div class="col-4 no-padding-right-global-grid-col">
                  <div
                    style={{
                      backgroundImage: `url(${key.category_picture})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      height: "35vw",
                      backgroundColor: "#ECCC2F",
                    }}
                  />
                </div>
                <div class="col-8">
                  <div className="title-category-content">{key.category}</div>
                  <p className="desc-category-content">
                    {key.description}
                  </p>
                </div>
              </div>
            </div>

            <Slider>
              {key.videos.map((video) => (
                <Slider.Item
                  movie={video}
                  key={video.admin_video_id}
                  first={false}
                />
              ))}
            </Slider>
          </section>
        </section>
      </React.Fragment>
    );
  };


  handleScroll = () => {
    var sectionIdCategoryID = document.getElementById(`PREFIXPROGRAM-${this.props.match.params.id}`);
    if (sectionIdCategoryID !== null) {
      let headerOffset = 70;
      let elementPosition = sectionIdCategoryID.getBoundingClientRect().top;
      let offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
    }
  }

  async componentDidMount() {
    await window.scrollTo(0, 0);
    document.getElementById("footer").style.display = await 'block'
    await this.fetchApiNewrReleaseSection();
    await this.handleScroll()
  }

  render() {
    const { t } = this.props;

    const { loading, maindata, banner, listCategoryAndVideo, slider } = this.state;

    

    return (
      <div className="main-sec-content" style={{marginTop:'61px'}}>
        {/* <SliderVindes items={slider}/> */}
        {loading ? <HomeLoader /> : <HomePageBanner banner={banner} />}
       
        <div className="main p-40 home-slider-top">
          {loading
            ? ""
            : maindata.map((mainDa, index) =>
                mainDa.data.length === 0
                  ? ""
                  : loading
                  ? t("loading")
                  : this.renderVideoList(mainDa, index)
              )}
          <div className="main-slidersec">
            <h3>Series</h3>
            <div className="category-container">
              {loading
                ? ""
                : listCategoryAndVideo.map((key, index) =>
                    key.length === 0
                      ? ""
                      : loading
                      ? t("loading")
                      : this.renderCategoryAndList(key, index)
                  )}
            </div>
          </div>
          
          {/* <div className="height-100" /> */}
        </div>
      </div>
    );
  }
}

export default translate(Home);
