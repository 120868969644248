import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import api from "../../Environment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./ArtSlider.scss";
import getYouTubeID from "get-youtube-id";

// import "./LatestLandingPage.css";
const $ = window.$;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="next-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="prev-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-left"></i>
    </div>
  );
}




class ArtSlider extends Component {
  constructor(props) {
    super(props);
}
  state = {
    isAuthenticated: this.props.data,
    footer_pages1: [],
    
    footer_pages2: [],
    loading: true,
    footerList: null,
    listCategoryAndVideo: []
  };

  componentDidMount() {
    this.fecthApiListHome()
  }

  renderCategories = (videos)=>{
    var indents = [];
    for (let i = 0; i <= 2; i++) {
      let index = 0
      // indents.push(<div className="categories dummies"  >{ videos.videos[index].category_name}</div>)
      // indents.push(<div className="categories dummies"  >{videos}</div>);
    }
    return indents;
     
      
    
    
  }

  async fecthApiListHome() {
    await api
      .postMethod("categories_home")
      .then(async (response) => {
        // console.log('inilah dia:'+ response.data.data)
        this.setState({
          listCategoryAndVideo: await response.data.data,
        });
      })
      .catch(function(error) {});
  }

  renderImageMobile(category){
    if (category.creation_type === "Video"){
      return (
        <div  className="item-art-mobile" style={{
          backgroundImage:  "url('https://img.youtube.com/vi/" +
          getYouTubeID(category.link_youtube) +
          "/sddefault.jpg')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundColor: "black"
        }}> </div> 
      )
    }else{
      if(category.portrait){
        return (
          <figure>
          <div   className="item-art-mobile" style={{
          backgroundImage: `url(${category.image})`,
          }}> </div>
          <figcaption className="item-name-mobile">{category.name}</figcaption>
        </figure> 
        )
      }
      return (
        <figure>
        <div   className="item-art-mobile" style={{
        backgroundImage: `url(${category.image})`,
        }}> </div>
        <figcaption className="item-name-mobile">{category.name}</figcaption>
      </figure> 
      )
    }
 
  }

  renderImage(category){
    if (category.creation_type === "Video"){
      return (
        <div  className="item-art-slider" style={{
          backgroundImage:  "url('https://img.youtube.com/vi/" +
          getYouTubeID(category.link_youtube) +
          "/sddefault.jpg')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundColor: "black"
        }}> </div> 
      )
    }else{
      if(category.portrait){
        return (
        <figure>
          <div   className="item-art-slider mobile portrait" style={{
          backgroundImage: `url(${category.image})`,
          }}> </div>
          <figcaption className="item-name">{category.name}</figcaption>
        </figure>
        
        )
      }
      return (
      <figure>
        <div   className="item-art-slider" style={{
        backgroundImage: `url(${category.image})`,
       }}> </div> 
        <figcaption className="item-name-art">{category.name}</figcaption>
        <figcaption className="item-count-like"><span className="fa fa-heart"></span> {category.count_like}</figcaption>
      </figure>
      )
    }
 
  }

  render() {
    // console.log('testprops:'+this.props.items[0].category)
    let items = ['',''];
    items = this.props.items
    // console.log('testprops'+Array.isArray(items))
    const settings = {
      dots: true,
      infinite: true,
      // autoplay: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };

    const settingsMobile = {
      dots: true,
      infinite: true,
      // autoplay: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    return (
      <>
      

      <div id="art-slider" className="landing-slider-desktop-wrapper">
      <Slider  className="art-main-slider" {...settings}>
        {
        items.map((category, i) => (
          
          <div className="container-item-art">
          <Link key={`${i}`} to={{
                pathname: category.linkUrl,
                creation: category
            }} >
          {/* <div key={`${i}`}  className="item-art-slider" style={{
              backgroundImage: `url(${category.image})`,
            }}>
              
          </div>  */}
              {
                this.renderImage(category)
              }
              
          </Link>
          
        </div>
            
            ))}
        </Slider>
      </div>

      <div className="landing-slider-mobile-wrapper">
      <Slider className="" {...settingsMobile}>
        {
        items.map((category, i) => (
          
          <div className="container-item-art">
            <Link key={`${i}`} to={{
                pathname:category.linkUrl,
                creation: category
            }} >
            {/* <div key={`${i}`}  className="item-art-mobile" style={{
                backgroundImage: `url(${category.image})`,
              }}>
            </div> */}
            {
                this.renderImageMobile(category)
              } 
            </Link>
          </div>
         
        ))}
        </Slider>
      </div>
      </>
    );
  }
}

export default ArtSlider;
