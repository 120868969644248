import React, { Component } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const $ = window.$;

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: this.props.data
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var headerHeight = $("#header").outerHeight();
        $(".header-height").height(headerHeight);

        // Call api function
    }

    render() {
        return (
            <div>
                <nav
                    className={`navbar-user navbar navbar-expand  main-nav fixed-top ${this.props.showNavbar}`}
                    id="header"
                >
                    
                    <Link className="navbar-brand abs" to="/">
                        <img
                            src={window.location.origin + "/assets/img/logo-vindes.png"}
                            className="logo-img desktop-logo"
                            alt={configuration.get("configData.site_name")}
                        />
                        <img
                            src={window.location.origin + "/assets/img/logo-vindes.png"}
                            className="logo-img mobile-logo"
                            alt={configuration.get("configData.site_name")}
                        />
                    </Link>
                </nav>
                {/*<div className="header-height"></div>*/}
            </div>
        );
    }
}

export default Header;
