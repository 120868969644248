import React from "react";
import Helper from "../../Helper/helper";
import { withToastManager } from "react-toast-notifications";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";

import { translate } from "react-multi-lang";
import configuration from "react-global-configuration";
import Axios from "axios";

class EditAccountComponent extends Helper {
    state = {
        data: [''],
        loading: true,
        selectedProvince: '0',
        loadingContent: null,
        buttonDisable: false,
        provinces: null,
        cities: null,
        
        selectedCity: 0
    };
    async componentDidMount() {
       
        
        api.postMethod("profile").then(response => {
            if (response.data.success === true) {
                let data = response.data.data;
                this.setState({ loading: false, data: data });
                Axios.get('https://admin.vindes.id/userApi/list_provinces')
                .then( async respon => {
                    let provinces = respon.data.provinsi
                    this.setState({ provinces: provinces });
                    // ranks.findIndex(rank => rank === 7)
                    let selProvince = "JAWA BARAT"
                    if(respon.data.provinsi.find(obj => obj.nama === this.state.data.province)){
                        selProvince = respon.data.provinsi.find(obj => obj.nama === this.state.data.province)
                    }
                    

                    
                    this.setState({selectedProvince: selProvince.id});
                    
                    document.getElementById('select-province').value=selProvince.id;

                    Axios.get('https://admin.vindes.id/userApi/list_cities?id_province='+ selProvince.id)
                    .then( res => {
                
                        
                        let cities = res.data.kota_kabupaten
                        this.setState({ cities: cities});
                        let selCity = ""
                        
                        if(this.state.data.city == null){
                           
                            document.getElementById('select-city').value=0
                        } else {
                            
                            this.setState({selectedCity : res.data.kota_kabupaten.find(obj => obj.nama === this.state.data.city).id});
                            // alert(JSON.stringify(res.data.kota_kabupaten.find(obj => obj.nama === this.state.data.city)))
                            document.getElementById('select-city').value=this.state.selectedCity
                        }
                        
                        
                    });
                })
            }
        });
        

        
        
    }
    selectProvince = (event) => {
        let province_id = event.target.value
        
        var index = event.nativeEvent.target.selectedIndex;
        let nama = event.nativeEvent.target[index].text
    
        // this.setState({provinceId : province_id });
        if( province_id > 0) {
          Axios.get('https://admin.vindes.id/userApi/list_cities?id_province='+ province_id)
          .then( res => {
    
            //   console.log(res.data.kota_kabupaten);
              let cities = res.data.kota_kabupaten
              this.setState({ cities: cities});
              this.setState(prevState => ({
                data: {                   // object that we want to update
                    ...prevState.data,    // keep all other key-value pairs
                    province: nama,
                    city: null       // update the value of specific key
                }
            }))
              
          })
        }

        
    }

    selectCity = (event) => {
        let province_id = event.target.value
        
        var index = event.nativeEvent.target.selectedIndex;
        let nama = event.nativeEvent.target[index].text
    
        // this.setState({provinceId : province_id });
        // this.setState({data:{city: nama }});
        this.setState(prevState => ({
            data: {                   // object that we want to update
                ...prevState.data,    // keep all other key-value pairs
                city: nama       // update the value of specific key
            }
        }))
    
      };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            loadingContent: this.props.t("button_loading"),
            buttonDisable: true
        });
        let userDetails = { ...this.state.data };
        const data = {
            name: userDetails.name,
            email: userDetails.email,
            mobile: userDetails.mobile,
            province: userDetails.province,
            city: userDetails.city,
        };

        api.postMethod("updateProfile", data).then(response => {
            if (response.data.success == true) {
                this.props.history.push("/account");
                ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                );
                this.setState({ loadingContent: null, buttonDisable: false });
            } else {
                ToastDemo(
                    this.props.toastManager,
                    response.data.error_messages,
                    "error"
                );
                this.setState({ loadingContent: null, buttonDisable: false });
            }
        });
       
    };
    render() {
        const { t } = this.props;

        const { loading, data, selectedProvince, selectedCity } = this.state;
        let selProvince = 0
        let selCity = 0
        if (selectedProvince === undefined){
            selProvince = 0
        }else{
            selProvince = selProvince
        }
        if (selectedCity === undefined){
            selCity = 0
        }else{
            selCity = selectedCity 
        }
        
        var formInput = {
            backgroundColor: 'white',
            height: '2.7em'
          };
        var bgImg = {
            backgroundImage: `url(${configuration.get(
                "configData.common_bg_image"
            )})`
        };

        
        return (
            <div className="edit-profile-sec-1 text-white">
                <div className="common-bg-img" >
                    <div className="main padding-top-md">
                        <div className="row">
                            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
                                <div className="register-box">
                                    <h3 className="register-box-head text-white">
                                        {t("edit")} {t("profile")}
                                    </h3>
                                    <form
                                        onSubmit={this.handleSubmit}
                                        className="auth-form"
                                    >
                                        <div className="form-group">
                                            <label htmlFor="name"  className="text-white">
                                                {t("full_name")}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={this.handleChange}
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                value={loading ? "" : data.name}
                                            />
                                        </div>
                                        <div className="form-group" >
                                            <label htmlFor="email" className="text-white">
                                                {t("email_address")}{" "}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={this.handleChange}
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={
                                                    loading ? "" : data.email
                                                }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="mobile" className="text-white">
                                                {t("mobile_number")}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={this.handleChange}
                                                className="form-control"
                                                id="mobile"
                                                name="mobile"
                                                value={
                                                    loading ? "" : data.mobile
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="form-group w-40" style={{width: '40%'}}>
                                            <label className="text-white"><b>Provinsi</b></label>
                                            {
                                                this.state.provinces !== null ?
                                                <select  className="form-control no-border-input-register" id="select-province" style={formInput} onChange={this.selectProvince}>                
                                                    <option value="0">Select Province</option>
                                                    {this.state.provinces.map( (item,index) => (
                                                    <option key={index} value={item.id}> {item.nama} </option>
                                                    ))}
                                                
                                                </select>
                                                : ""

                                            }
                                            
                                            </div>
                                            <div className="form-group" style={{width: '50%'}}>
                                            <labe className="text-white"l><b>Kota</b></labe>
                                            {
                                                this.state.cities !== undefined || this.state.cities !== null?
                                                    <select  className="form-control no-border-input-register" id="select-city"  style={formInput}  onChange={this.selectCity}>
                                                    
                                                    <option selected="" value={selCity.id} >Select City</option>
                                                    {this.state.cities ?this.state.cities.map( (item) => (
                                                        <option value={item.id}> {item.nama} </option>
                                                        )):""}
                                                        
                                                        
                                                    </select>
                                                    : ""
                                            }
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-danger auth-btn mt-4"
                                            disabled={this.state.buttonDisable}
                                        >
                                            {this.state.loadingContent != null
                                                ? this.state.loadingContent
                                                : this.props.t("save")}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withToastManager(translate(EditAccountComponent));
