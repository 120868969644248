import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import api from "../../Environment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./AudioSlider.scss";

const $ = window.$;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="next-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="prev-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-left"></i>
    </div>
  );
}

class AudioSlider extends Component {
    constructor(props) {
        super(props);
    }

  renderImageMobile(audio){
    return (
    <figure>
        <div   className="item-art-mobile" style={{
            backgroundImage: `url(${audio.thumbnail_image})`,
        }}> </div>
        <figcaption className="item-name-mobile">{audio.title}</figcaption>
    </figure> 
    )
  }

  renderImage(audio){
    return (
    <figure>
        <div   className="item-art-slider mobile portrait" style={{
        backgroundImage: `url(${audio.thumbnail_image})`,
        }}> </div>
        <figcaption className="item-name-article">{audio.title}</figcaption>
    </figure>
    )
  }

  render() {
    let items = ['',''];
    items = this.props.items
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };

    const settingsMobile = {
      dots: true,
      infinite: true,
      // autoplay: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    return (
      <>
      <div id="art-slider" className="landing-slider-desktop-wrapper">
      <Slider  className="art-main-slider" {...settings}>
        {
        items.map((audio, i) => (
          
          <div className="container-item-art">
          <Link key={`${i}`} to={{
                pathname: audio.linkUrl,
                audio_id: audio.id}} >
              {
                this.renderImage(audio)
              }
              
          </Link>
          
        </div>
            
            ))}
        </Slider>
      </div>

      <div className="landing-slider-mobile-wrapper">
      <Slider className="" {...settingsMobile}>
        {
        items.map((audio, i) => (
          
          <div className="container-item-art">
            <Link key={`${i}`} to={{
                pathname:audio.linkUrl,
                audio_id: audio.id
            }} >
                {
                this.renderImageMobile(audio)
              } 
            </Link>
          </div>
         
        ))}
        </Slider>
      </div>
      </>
    );
  }
}

export default AudioSlider;
