import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "./LatestLandingPageResponsive.css";
import "./LatestLandingPage.css";
import BannerImageBg from "./banner-new-bg.jpg";
import Logo from "../logo-vindes.png"
import configuration from "react-global-configuration";
import { apiConstants } from "../../components/Constant/constants";
import { translate, getLanguage, t } from "react-multi-lang";
import LatestFooter from "./LatestFooter";
import Footer from "../Layouts/SubLayout/Footer";
import api from "../../Environment";
import renderHTML from "react-render-html";
import ImageLoader from "../Helper/ImageLoader";
import Slider from "react-slick";
import ContentLoader from "../Static/contentLoader";
import ParallaxContainer from "./ParallaxContainer";
import TesCom from "./TesCom";
import SliderVindes from "../Slider/SliderVindes"
import NavbarLanding from "../Navbar/Navbar-landing";
import LandingHeader from "../Layouts/SubLayout/LandingHeader"
import Navbar from "../Navbar/Navbar";
import { Nav } from "react-bootstrap";
import LandingSlider from "../LandingSlider/LandingSlider";
import UserHeader from "../Layouts/SubLayout/UserHeader"
import PromoSlider from "../PromoSlider/PromoSlider";
import ArtSlider from "../ArtSlider/ArtSlider";
import ArtikelSlider from "../Artikel/ArtikelSlider";
import { assertTryStatement } from "@babel/types";
import EventSlider from "../Events/EventSlider";
import AudioSlider from "../Audio/AudioSlider";


  const umkmss = [
    process.env.PUBLIC_URL + '/assets/img/ganjar.jpeg',
    process.env.PUBLIC_URL + '/assets/img/ganjar.jpeg',
    process.env.PUBLIC_URL + '/assets/img/ganjar.jpeg',
    process.env.PUBLIC_URL + '/assets/img/ganjar.jpeg',]
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="next-arrow"
      onClick={onClick}
    >
      <img src={process.env.PUBLIC_URL + '/assets/img/arrow-right-black.png'} alt="" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}




class LatestLandingPage extends Component {
  state = {
    HomeSettings: [],
    loading: true,
    loadingData: true,
    faqData: null,
    videoList: null,
    loadingProgram: true,
    skipCount: 0,
    loadMoreButtonDisable: false,
    loadingContent: null,
    mainData: null,
    isHidden: "hidden",
    isActive: "",
    showNavbar: false,
    artTitle: '',
    artDescription: '',
    promoDescription: '',
    merchDescription: '',

    // programs: []
  };

  constructor(props){
    super(props);
    this.myRef = React.createRef()
    this.state = {
      scrollTop: 0,
      programs: [],
      arts: [],
      merch: [],
      slider: [],
      promos: [],
      events: [],
      artikel:[],
      audio:[]
    }
}

// hideBar = () => {
//   console.log('fuck')
//   const { isHide } = this.state
//   window.scrollY > this.prev ?
//   !isHide && this.setState({ isHide: true })
//   :
//   isHide && this.setState({ isHide: false });
//   this.prev = window.scrollY;
// }

hideBar = () => {
  const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > 10) {
      this.setState({ showNavbar: "shows" })
    } else {
      this.setState({ showNavbar: "hide" })
    }

}

getInitialState(){
  return {"showHideSidenav":"hidden"};
}

toggleSidenav() {
  // if (this.state.isActive === "hidden"){
  //   this.setState(isActive: "show")
  // }
  this.setState((this.state.isHidden === "hidden") ? {isHidden:"show"} : {isHidden:"hidden"});
  this.setState((this.state.isHidden === "hidden") ? {isActive:"active"} : {isActive:""});
}
  
  
  componentDidMount() {
    document.getElementById("footer").style.display = 'block'
    this.getProgramSection()
    window.addEventListener('scroll', this.hideBar, true);
    this.fetchConfig();
    this.getFaqs();
    // this.getArtSection();
    // this.getPromo();
    // this.getMerchSection();
    this.getSlider();
    this.getArtDescription();
    this.getAllSections();
    this.getEvent();
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.hideBar);
}

  async getArtDescription() {
    await api
      .getMethod("vindes_page_settings")
      .then(async (response) => {
        // console.log('inilah dia art:'+ response.data.data.art_description)

        this.setState({
          artDescription: response.data.data.art_description,
          merchDescription: response.data.data.merch_description,
          promoDescription: response.data.data.promo_description,
          loadingProgram: await false
        });
        
      })
      .catch(function(error) {});
  }

  async getProgramSection() {
    await api
      .postMethod("categories_home")
      .then(async (response) => {
        let arrayImages = []
        response.data.data.forEach(element => {
          let item = {image: element.category_picture, id: element.category_id, linkUrl: 'program/'+element.category_id}
          arrayImages.push(item)
        });
        this.setState({
          programs: response.data.data,
          loadingProgram: await false
        });
        // console.log('inilah dia:'+ programs)
      })
      .catch(function(error) {});
  }

  async getArtSection() {
    await api
      .postMethod("creations/list")
      .then(async (response) => {
        let arrayItems = []
        response.data.data.forEach(element => {
          let item = {
            image: element.image, 
            description: element.description, 
            tags: element.tags,
            name: element.name,
            linkUrl: '/art',
            id: element.id
          }
            // console.log('islike:'+element.like)
            arrayItems.push(item)
        });
        this.setState({
          arts: arrayItems,
          loadingProgram: await false
        });
        // console.log('inilah dia:'+ programs)
        
      })
      .catch(function(error) {});
  }

  async getMerchSection() {
    await api
      .postMethod("stores/list")
      .then(async (response) => {
        let arrayImages = []
        response.data.data.forEach(element => {
          let item = {
            image: element.product_image,
            product_name: element.product_name,
            link_tokopedia: element.link_tokopedia,
            link_bukalapak: element.link_bukalapak,
            link_lazada: element.link_lazada,
            product_image: element.product_image, 
            description: element.description, 
            tags: element.tags,
            name: element.name,
            portrait: true,
            linkUrl: '/merch'}
            arrayImages.push(item)
        });
        this.setState({
          merch: arrayImages,
          loadingProgram: await false
        });
        // console.log('inilah dia:'+ programs)
        
      })
      .catch(function(error) {});
  }

  async getAllSections() {
    await api
    .postMethod("home/section_vindes")
    .then(async (response) => {
      let arrayMerch = []
      let arrayArt = []
      let arrayPromos = []
      let arrayArtikel = []
      let arrayAudio = []
      response.data.data.list_merch.forEach(element => {
        let item = {
          image: element.product_image,
          product_name: element.product_name,
          link_tokopedia: element.link_tokopedia,
          link_bukalapak: element.link_bukalapak,
          link_lazada: element.link_lazada,
          product_image: element.product_image, 
          description: element.description, 
          tags: element.tags,
          name: element.product_name,
          portrait: true,
          linkUrl: '/merch'}
          arrayMerch.push(item)
      });

      response.data.data.list_art.forEach(element => {
        let item = {
          image: element.image, 
          description: element.description, 
          tags: element.tags,
          name: element.sender_name,
          linkUrl: '/art',
          count_like : element.count_like,
          id: element.id
        }
          arrayArt.push(item)
      });

      response.data.data.list_promoc2.forEach(element => {
        let item = {
          image: element.image, 
          link_instagram: element.link_instagram,
          description: element.description, 
          name: element.name,
          portrait: true,
          linkUrl: 'promoc2'}
          arrayPromos.push(item)
      });

      response.data.data.list_articles.forEach(element => {
        let item = {
          thumbnail_image: element.thumbnail_image,
          title: element.title,
          creator: element.creator,
          date: element.created_at,
          content: element.content,
          linkUrl: '/artikel',
          id: element.id}
          arrayArtikel.push(item)
      });

      response.data.data.list_audio.forEach(element => {
        let item = {
          thumbnail_image: element.thumbnail_image,
          title: element.title,
          linkUrl: '/audio',
          id: element.id}
        arrayAudio.push(item)
      });

      this.setState({
        merch: arrayMerch,
        arts: arrayArt,
        promos: arrayPromos,
        artikel: arrayArtikel,
        audio: arrayAudio,
        loadingProgram: await false
      });
    })
    .catch(function(error) {});
  }

  async getSlider() {
    await api
      .postMethod("sliders/list", {page: "Home"})
      .then(async (response) => {
        let arrayImages = []
       
        await this.setState({
          slider: response.data.data,
          loadingProgram: await false
        });
        // console.log('inilah dia slider:'+ response.data.data)
        
      })
      .catch(function(error) {});
  }

  async getPromo() {
    await api
      .postMethod("umkms/list")
      .then(async (response) => {
        let arrayImages = []
        response.data.data.forEach(element => {
          let item = {
            image: element.image, 
            link_instagram: element.link_instagram,
            description: element.description, 
            name: element.name,
            portrait: true,
            linkUrl: 'promoc2'}
            arrayImages.push(item)
        });
        this.setState({
          promos: arrayImages,
          loadingProgram: await false
        });
        // console.log('inilah dia:'+ programs)
        
      })
      .catch(function(error) {});
  }

  async getEvent() {
    await api
      .postMethod("events/v2/list")
      .then(async (response) => {
       
        await this.setState({
          events: response.data.data.upcoming_event,
          loadingProgram: await false
        });
        // console.log('inilah dia event:'+ response.data.data.upcoming_event)
        
      })
      .catch(function(error) {});
  }


  viewAllApiCall = (inputData, apiURL) => {
    let items;
    let secondItem;
    api
      .postMethod(apiURL, inputData)
      .then((response) => {
        if (response.data.success) {
          if (this.state.mainData != null) {
            items = [...this.state.mainData, ...response.data.data];
            secondItem = [...this.state.mainData, ...response.data.data];
          } else {
            items = [...response.data.data];
            secondItem = [...response.data.data];
          }
          // console.log("Items", items);
          this.setState({
            mainData: items,
            videoList: secondItem,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadMoreButtonDisable: false,
            loadingContent: null,
          });
          setTimeout(() => {
            // console.log("State", this.state.videoList);
          }, 1000);
        } else {
        }
      })
      .catch(function(error) {});
  };

  chunkArray(myArray, chunk_size) {
    let results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.homeSettingsUrl);
    const homeResonse = await response.json();

    this.setState({
      loading: false,
      HomeSettings: homeResonse.data,
    });
  }

  async getFaqs() {
    api.getMethod("faqs/list").then((response) => {
      if (response.data.success) {
        this.setState({
          faqData: response.data.data,
          loadingData: false,
        });
      } else {
      }
    });
  }

  renderSlider(items){
    if (this.state.loadingProgram === false){
      return(
    <LandingSlider items={items}>
                  
    </LandingSlider>
    )
    }
    
  }

  renderArtSlider(items){
    // console.log('art:'+items)
    if (this.state.loadingProgram === false){
      return(
    <ArtSlider items={items}>
                  
    </ArtSlider>
    )
    }
    
  }

  renderEventSlider(items){
    // console.log('art:'+items)
    if (this.state.loadingProgram === false){
      return(
    <EventSlider items={items}>
                  
    </EventSlider>
    )
    }
    
  }

  renderPromoSlider(items){
    if (this.state.loadingProgram === false){
      return(
      <ArtSlider items={items}>
                    
      </ArtSlider>
      )
    }
    
  }

  renderArtikelSlider(items){
    if (this.state.loadingProgram === false){
      return(
      <ArtikelSlider items={items}>
                    
      </ArtikelSlider>
      )
    }
    
  }

  renderAudioSlider(items){
    if (this.state.loadingProgram === false){
      return(
      <AudioSlider items={items}>
                    
      </AudioSlider>
      )
    }
    
  }
  render() {
    // return <Redirect to="/parallax" />;
    const classHide = this.state.isHide ? 'hide' : '';
    
    
    let result = null;
    const { loading, loadingProgram, HomeSettings, loadingData, faqData } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3.3,
      slidesToScroll: 2,
      nextArrow: <SampleNextArrow />,
    };
    const startDate = new Date();
    const endDate = new Date("2021-08-01")
    if (!localStorage.getItem("userId")  && !localStorage.getItem("accessToken") && !localStorage.getItem("userId")){
      localStorage.setItem("userId", 58);
      localStorage.setItem("accessToken", '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi');
      localStorage.setItem("userType", 0);
      localStorage.setItem("push_status", 1);
      localStorage.setItem("username", 0);
      localStorage.setItem(
        "active_profile_id",
        1
      );
    }

    // if (localStorage.getItem("userId")  && localStorage.getItem("accessToken") && localStorage.getItem("userId") !== 58 && localStorage.getItem("accessToken") !== '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi'
    // ){
    //   return <Redirect to="/program" />;
    // }else{
      
    //   if (startDate < endDate ) {
    //     return <Redirect to="/countdown" />;
    //    }
    // }

    return (
      <>
      <UserHeader showNavbar={this.state.showNavbar}></UserHeader>
      <div className="parallax-container">
        <TesCom />
      </div>
      <div className="slider-vindes">
        <SliderVindes items={this.state.slider} ></SliderVindes>
      </div>
          <div className="list-content">
            <div className="container-section-art">
            <div className="container-title d-flex">
                 {/* <h3>PROGRAM</h3> */}
                <img class="image-title" src={process.env.PUBLIC_URL+`/assets/img/Program.png`} alt="" />
              </div>     
              {this.renderSlider(this.state.programs)}
            </div>

            
           <div>
           
            <div className="container-section-art"> 
            <div className="background-art-desktop" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bgweb-art-1.jpg`}}>
              
              </div>
              <div className="background-art-mobile" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bgweb-art-1.jpg`}}>
              
              </div>
              <div className="background-art-mobile">
              </div>
              <div className="container-title title-art d-flex">
              <img class="image-title" src={process.env.PUBLIC_URL+`/assets/img/Art.png`} alt="" />
              </div> 
              <div className="landing-slider-desktop-wrapper">  
              <div className="d-flex flex-col">
                {/* <div className="banner-art" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/artbanner.png`}}></div>  */}
                <div className="container-inner-art  d-flex flex-col col-md">
                
                    {/* <div className="container-text d-flex h-100 align-items-end" dangerouslySetInnerHTML={{__html: this.state.artDescription}} /> */}
                
                  <div className="art-slider-container"> 
                    {this.renderArtSlider(this.state.arts)}
                  </div>
                </div>
              </div>
              </div> 

              <div className="landing-slider-mobile-wrapper">
                <div className="d-flex flex-col">
                  <div className="container-inner-art  d-flex flex-col">
                    <div className="container-">
                      <div className="d-flex mb-2">
                        {/* <div className="banner-art-mobile" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/artbanner.png`}}></div>  */}
                        <div className="mobile-art-detail-container ml-3">
                        {/* <div className="container-text d-flex h-100 align-items-end mobile-detail-art" dangerouslySetInnerHTML={{__html: this.state.artDescription}}  >
                        </div> */}
                        </div>
                      </div>
                     </div>
                     <div className="art-slider-container"> 
                      {this.renderArtSlider(this.state.arts)}
              
                    </div>
                    </div>
            </div>
            </div>

       
            </div>
            <div className="container-section-art"> 
           
            {/* <div className="background-merch-desktop" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bgweb-merch-1.jpg`}}>
              
              </div> */}
              <div className="container-title title-art d-flex">
                {/* <h3>MERCH</h3> */}
                <img class="image-title" src={process.env.PUBLIC_URL+`/assets/img/Store.png`} alt="" />
              </div> 
              <div className="landing-slider-desktop-wrapper">  
              <div className="d-flex flex-col">
                {/* <div className="banner-art" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/merchandise.png`}}></div>  */}
                <div className="container-inner-art  d-flex flex-col col-md">
                
                    {/* <div className="container-text d-flex h-100 align-items-end" dangerouslySetInnerHTML={{__html: this.state.merchDescription}} /> */}
                    
                
                  <div className="art-slider-container"> 
                    {this.renderArtSlider(this.state.merch)}
                  </div>
                </div>
              </div>
              </div> 


              <div className="landing-slider-mobile-wrapper">
                <div className="d-flex flex-col">
                  <div className="container-inner-art  d-flex flex-col">
                    <div className="container-">
                      <div className="d-flex mb-2">
                        {/* <div className="banner-art-mobile" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/merchandise.png`}}></div>  */}
                        <div className="mobile-art-detail-container ml-3">
                          {/* <div className="container-text d-flex h-100 align-items-end mobile-detail-art" dangerouslySetInnerHTML={{__html: this.state.merchDescription}} /> */}
                        </div>
                      </div>
                     </div>
                     <div className="art-slider-container"> 
                      {this.renderArtSlider(this.state.merch)}
              
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>


          <div className="container-section-art"> 
          <div className="background-art-desktop" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bgweb-art-1.jpg`}}>
              
              </div>
              <div className="background-promo-mobile" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bgweb-art-1.jpg`}}>
              
              </div>
             
              <div className="container-title title-art d-flex">
              <img class="image-title" src={process.env.PUBLIC_URL+`/assets/img/PromoC2.png`} alt="" />
              </div> 
              <div className="landing-slider-desktop-wrapper">  
              <div className="d-flex flex-col">
                {/* <div className="banner-art" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/UMKM.png`}}></div>  */}
                <div className="container-inner-art  d-flex flex-col col-md" >
                
                    {/* <div className="container-text d-flex h-100 align-items-end" dangerouslySetInnerHTML={{__html: this.state.promoDescription}}></div> */}
                
                  <div className="art-slider-container"> 
                  {this.renderPromoSlider(this.state.promos)}
                  </div>
                </div>
              </div>
              </div> 

              <div className="landing-slider-mobile-wrapper">
                <div className="d-flex flex-col">
                  <div className="container-inner-art  d-flex flex-col">
                    <div className="container-">
                      <div className="d-flex mb-2">
                        {/* <div className="banner-art-mobile" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/UMKM.png`}}></div>  */}
                        <div className="mobile-art-detail-container ml-3">
                        <div className="container-text d-flex h-100 align-items-end mobile-detail-art" >
                        {/* <div dangerouslySetInnerHTML={{__html: this.state.promoDescription}}></div> */}
                        </div>
                        </div>
                      </div>
                     </div>
                     <div className="art-slider-container"> 
                      {this.renderPromoSlider(this.state.promos)}
              
                    </div>
                  </div>
                </div>
              </div>

              
            </div>

            <div className="container-section-art"> 
              <div className="container-title title-art d-flex">
                
                <img class="image-title" src={process.env.PUBLIC_URL+`/assets/img/Event.png`} alt="" />
              </div> 
              <div className="landing-slider-desktop-wrapper">  
              <div className="d-flex flex-col">
                <div className="container-inner-art  d-flex flex-col col-md">
                
                    
                
                  <div className="art-slider-container"> 
                    {this.renderEventSlider(this.state.events)}
                  </div>
                </div>
              </div>
              </div> 


              <div className="landing-slider-mobile-wrapper">
                <div className="d-flex flex-col">
                  <div className="container-inner-art  d-flex flex-col">
                    <div className="container-">
                      <div className="d-flex mb-2">
                        <div className="mobile-art-detail-container ml-3">
                          </div>
                      </div>
                     </div>
                     <div className="art-slider-container"> 
                      {this.renderEventSlider(this.state.events)}
              
                    </div>
                  </div>
                </div>
              </div>

              
            </div>

          </div>
          <div className="gap-download"></div>
          <div className="section-button-download">
            <div style={{ color: "white" }} className="text-center"><strong>Download Sekarang</strong></div>
            <div className="text-center">
              <a target="_blank" href="https://www.apple.com/app-store/">
                <img className="img-store" src={process.env.PUBLIC_URL + '/assets/img/appstore.png'} alt="" />
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=id.vindes">
                <img className="img-store" src={process.env.PUBLIC_URL + '/assets/img/playstore.png'} alt="" />
              </a>
            </div>
          </div>
      </>
    );
  }
}

export default LatestLandingPage;
