import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import './ThankYouModal.css';

class ThankYouModal extends Component {
    render(props) {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide(false)} centered  size="lg" onClick={() => this.props.onHide(false)}>
                <Modal.Body id="modal-body-thank">
                    <div className="text-content">
                        Terima Kasih <br/>
                        sudah mendaftar
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ThankYouModal;