import React from "react";
import { Link } from "react-router-dom";
import Helper from "../../Helper/helper";
import { apiConstants } from "../../Constant/constants";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import configuration from "react-global-configuration";
import "./subLayout.scoped.css";
import { translate } from "react-multi-lang";

// style
import userHeaderStyle from "./UserHeader.module.scss";
const $ = window.$;
class UserHeader extends Helper {
    constructor(props) {
        super(props);
    }
    state = {
        loading: true,
        activeProfile: null,
        loadingCategory: true,
        categories: null,
        loadingNotification: true,
        notificationCount: null,
        notifications: null,
        playButtonClicked: false,
        value: "",
        suggestions: null,
        mobileSidebar: false,
        loadingSuggesstion: true,
        displaySuggesstion: "none",
        searchInputFocusClass: "",
        showSubMenu: 'hideMenu',
        isLoggedIn: false,
        navbarList: [
            {
                title: 'PROGRAM',
                link:'/program'
            },
            {
                title: 'ART',
                link:'/art'
            },
            {
                title: 'STORE',
                link:'/merch'
            },
            {
                title: 'PROMOC2',
                link:'/promoc2'
            },
            {
                title: 'EVENT',
                link:'/event'
            },
            // {
            //     title: 'ARTIKEL',
            //     link:'/artikel'
            // },
            // {
            //     title: 'AUDIO',
            //     link:'/audio'
            // }
        ],
        subNavbarList:[
            {
                title: 'VINCENT DESTA SHOW',
                link: '#'
            },
            {
                title: 'NGAPAIN BANG!',
                link: '#'
            },
            {
                title: 'JASIMAN',
                link: '#'
            },
            {
                title: 'DUO',
                link: '#'
            }
        ]
    };

    searchInputFocus = ({ currentTarget: input }) => {
        this.setState({ searchInputFocusClass: "search-focus" });
    };

    handleOnSubmit = (event, value) => {
        event.preventDefault();
    };

    searchResult = () => {
        api.postMethod("search_videos")
            .then(response => {
                if (response.data.success === true) {
                    let notificationCount = response.data.count;
                    let notifications = response.data.data;
                    this.setState({
                        loadingNotification: false,
                        notificationCount: notificationCount,
                        notifications: notifications
                    });
                } else {
                }
            })
            .catch(function (error) { });
    };

    handleNotificationChange = ({ currentTarget: input }) => {
        let inputData;
        if (input.checked) {
            inputData = 1;
        } else {
            inputData = 0;
        }
        api.postMethod("settings", { status: inputData })
            .then(response => {
                if (response.data.success) {
                    localStorage.setItem(
                        "push_status",
                        response.data.push_status
                    );
                } else {
                }
            })
            .catch(function (error) { });
    };

    changeProfile = (profile, event) => {
        event.preventDefault();

        localStorage.removeItem("active_profile_id");
        localStorage.setItem("active_profile_id", profile.sub_profile_id);
        localStorage.setItem("active_profile_image", profile.picture);
        localStorage.setItem("active_profile_name", profile.name);

        window.location = "/program";
    };

    handlePlayVideo = async (event, admin_video_id) => {
        event.preventDefault();

        let inputData = {
            admin_video_id: admin_video_id
        };

        await this.onlySingleVideoFirst(inputData);

        if (this.state.videoDetailsFirst.success === false) {
            ToastDemo(
                this.props.toastManager,
                this.state.videoDetailsFirst.error_messages,
                "error"
            );
        } else {
            this.redirectStatus(this.state.videoDetailsFirst);
            this.setState({ playButtonClicked: true });
        }
    };

    showSubMenu= ( event) => {
        event.preventDefault();
        if(this.state.showSubMenu === "hideMenu")
      {
        this.setState({showSubMenu:'showMenu'})
        
      }
    else
      {
        this.setState({showSubMenu:'hideMenu'})
      }
    }

    componentDidMount() {
        this.viewProfiles();
        let inputData = {};
        api.postMethod("v4/categories/list", inputData)
            .then(response => {
                if (response.data.success === true) {
                    let categories = response.data.data;

                    this.setState({
                        loadingCategory: false,
                        categories: categories
                    });
                } else {
                }
            })
            .catch(function (error) { });
        // Notification count API
        let notificationInputData = {
            skip: 0,
            take: 4
        };
        api.postMethod("notifications", notificationInputData)
            .then(response => {
                if (response.data.success === true) {
                    let notificationCount = response.data.count;
                    let notifications = response.data.data;
                    this.setState({
                        loadingNotification: false,
                        notificationCount: notificationCount,
                        notifications: notifications
                    });
                } else {
                }
            })
            .catch(function (error) { });
            if (localStorage.getItem("userId") !== '58' ){
                // console.log('login?:'+localStorage.getItem("userId")+'/'+localStorage.getItem("userId"))
                this.setState({isLoggedIn: true})
            }
    }

    componentDidUpdate(){
        
    }

    handleSearchChange = ({ currentTarget: input }) => {
        if (input.value != "") {
            this.setState({ displaySuggesstion: "block" });
        } else {
            this.setState({ displaySuggesstion: "none", searchInputFocusClass: "" });
        }
        api.postMethod("search_videos", { key: input.value })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        suggestions: response.data.data,
                        loadingSuggesstion: false
                    });
                    if (response.data.data.length <= 0) {
                        this.setState({
                            searchInputFocusClass: "",
                        });
                    }
                } else {
                }
            })
            .catch(function (error) { });
    };

    // renderList = activeProfile => {
    //     return (
    //         <div>
    //             {activeProfile.map(profile =>
    //                 profile.sub_profile_id ==
    //                     localStorage.getItem("active_profile_id") ? (
    //                         ""
    //                     ) : (
    //                         <Link
    //                             className="dropdown-item"
    //                             key={profile.sub_profile_id}
    //                             to="/view-profiles"
    //                             onClick={event =>
    //                                 this.changeProfile(profile, event)
    //                             }
    //                         >
                                
    //                             <div className="display-inline">
    //                                 <div className="left-sec">
    //                                     <img
    //                                         src={profile.picture}
    //                                         alt="profile_img"
    //                                     />
    //                                 </div>
    //                                 <div className="right-name">{profile.name}</div>
    //                             </div>
    //                         </Link>
    //                     )
    //             )}
    //         </div>
    //     );
    // };

    toggleMobileSidebar = () => {
        this.setState({
            mobileSidebar: !this.state.mobileSidebar
        });
    };
    renderNavList = (key,index) => {
        if(this.props.activeNav === key.title){
            return(
                <React.Fragment key={key}>
                    
                <Link
                    className={`nav-link pt-0 pb-0 h-100 align-items-center d-flex align-items-center active`}
                    to={key.link}
                >
                    {key.title}
                </Link>
                
                </React.Fragment>
                
            )
        }else{
            return(
            <React.Fragment key={key}>
                
            <Link
                className="nav-link pt-0 pb-0 h-100 align-items-center d-flex align-items-center"
                to={key.link}
            >
                {key.title}
            </Link>
            
            </React.Fragment>
            
        )
        }
        
    }

    renderNavLogin =()=>{
        if (localStorage.getItem("userId") !== '58'){
           
            return (
            <React.Fragment>
                <li className="desktop-nav nav-item dropdown">
            {/* heres profile image */}
            <Link
                className="nav-link dropdown-toggle"
                to="#"
                data-toggle="dropdown"
            >
                <img className={userHeaderStyle.iconUser} src={process.env.PUBLIC_URL + '/assets/img/profile.png'} alt="" />
            </Link>
            <div className="dropdown-menu profile-drop">
                
                <Link className="dropdown-item" to="/account">
                    Profile
                </Link>
                
                <Link className="dropdown-item" to={"/logout"}>
                    Sign Out
                </Link>
            </div>

            
        </li>
        
            </React.Fragment>
                );
        }else{
           
            return (
                <React.Fragment>
                   
                <li className="desktop-nav nav-item dropdown mobile-view">
                    {/* heres profile image */}
                    <Link to="/register" className={userHeaderStyle.buttonSignup} >
                        SIGN UP
                    </Link>
        
                </li>
                <li className="desktop-nav nav-item dropdown mobile-view">
                    {/* heres profile image */}
                    <Link to="/login" className="btn button-rounded">
                        SIGN IN
                    </Link>
        
                </li>
                </React.Fragment>
                    );
        }
        
    }

    renderNavMobileLogin =()=>{
        if (localStorage.getItem("userId")  && localStorage.getItem("accessToken") && localStorage.getItem("userId") !== 58 && localStorage.getItem("accessToken") !== '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi'
        ){
            return (
            <React.Fragment>
                <li className="nav-item dropdown" key="browse-mobile-header">
                    <Link
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        to="#"
                    >
                        USER
                    </Link>
                    <div className="dropdown-menu browse">
                        <Link className="dropdown-item" to="/account">
                            PROFILE
                        </Link>
                        
                        <Link className="dropdown-item" to={"/logout"}>
                            SIGN OUT
                        </Link>
                    </div>
                </li>
            </React.Fragment>
                );
        }else{
            return (
                <React.Fragment>
                   <li className="nav-item " >
                        <Link
                            className="nav-link  "
                            to="/login"
                        >
                            SIGN IN
                        </Link>
                    </li>
                    <li className="nav-item " >
                        <Link
                            className="nav-link  "
                            to="/register"
                        >
                            SIGN UP
                        </Link> 
                    </li>
                                
                </React.Fragment>
                    );
        }
        
    }


    render() {
        const { t } = this.props;

        const {
            loading,
            activeProfile,
            loadingCategory,
            categories,
            loadingNotification,
            notificationCount,
            notifications,
            value,
            suggestions,
            loadingSuggesstion,
            navbarList,
            subNavbarList
        } = this.state;
        
        const recentSearches = [
            "star wars 1",
            "star wars 2",
            "star trek 3",
            "star wars 4",
            "aaa"
        ];

        const placeholder = "title...";

        const inputPosition = "center";



        if (this.state.playButtonClicked) {
            const returnToVideo = this.renderRedirectPage(
                this.state.videoDetailsFirst
            );

            if (returnToVideo != null) {
                return returnToVideo;
            }
        }

        const inputProps = {
            placeholder: "Type...",
            value,
            onChange: this.onChange
        };
        
        
        

        return (
            <div>
                <nav
                    className={`navbar-user navbar navbar-expand  main-nav fixed-top ${userHeaderStyle.navbarHome} ${this.props.showNavbar}`}
                    id="header"
                >
                    <span
                        className="mobile-nav menu-icon"
                        id="menu_icon"
                        onClick={() => this.toggleMobileSidebar()}
                    >
                        <Link className="navbar-brand abs" to="#">
                        <i class="m-2 fa fas fa-solid fa-bars"></i>
                        </Link>
                    </span>
                    <Link className="navbar-brand abs" to="/">
                        <img
                            src={window.location.origin + "/assets/img/logo-vindes.png"}
                            className="logo-img desktop-logo"
                            alt={configuration.get("configData.site_name")}
                        />
                        <img
                            src={window.location.origin + "/assets/img/logo-vindes.png"}
                            className="logo-img mobile-logo"
                            alt={configuration.get("configData.site_name")}
                        />
                    </Link>
                    <ul className="navbar-nav desktop-nav h-100 align-items-center ">
                        {
                        navbarList.map((key, index) => ( 
                            this.renderNavList(key,index)
                        ))
                        }
                        
                    </ul>
                    
                    <ul className="navbar-nav ml-auto align-items-center">
                        <li className="nav-item">
                            <a href="https://twitter.com/vindestwitt" target="_blank" className="button-sosmed">
                                <img src={process.env.PUBLIC_URL + '/assets/img/twitter.png'} alt="" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://vt.tiktok.com/ZGJSM4P5o/" target="_blank" className="button-sosmed">
                                <img src={process.env.PUBLIC_URL + '/assets/img/tiktok.png'} alt="" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.instagram.com/vindes.ig/" target="_blank"className="button-sosmed">
                                <img src={process.env.PUBLIC_URL + '/assets/img/instagram.png'} alt="" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.youtube.com/vindes" target="_blank" className="button-sosmed">
                                <img src={process.env.PUBLIC_URL + '/assets/img/youtube.png'} alt="" />
                            </a>
                        </li>
              
                       
                        
                        {
                            this.renderNavLogin()
                        }

                        
                    </ul>
                </nav>
                
                <section>
                
            </section>

                <div className="header-height" />

                <div
                    className="mobile-sidebar"
                    id="menu_content"
                    style={{
                        display: this.state.mobileSidebar ? "block" : "none"
                    }}
                >
                    <div className="sidebar-content">
                  
                        <ul className="sidebar-menu" id="mobile-side-menu">
                            {
                                navbarList.map((key, index) => ( 
                                    <li className="nav-item " key={index} >
                                    <Link
                                        className="nav-link  "
                                        to={key.link}
                                    >
                                        {key.title}
                                    </Link>
                                    </li>
                                ))
                            }
                           

                           
                                {this.renderNavMobileLogin()}
                                
                            
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default withToastManager(translate(UserHeader));
