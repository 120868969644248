import React, { Component } from "react";
import { Link,Redirect   } from "react-router-dom";
import Helper from "../Helper/helper";
import { withToastManager } from "react-toast-notifications";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import { translate } from "react-multi-lang";
import Axios from "axios";
import "./SubmitArt.scss";
import { useDropzone } from 'react-dropzone';
import DropzoneComponent from './DropzoneComponent';
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";

class SubmitArt extends Helper {  

  constructor(props) {
    super(props);
    this.state = {
      file: '',
      name: '',
      senderName: '',
      description: '',
      linkInstagram: '',
      type: 'Artwork',
      youtube: '',
      audio: '',
      image: '',
      data: {
        name: "",
        image: "",
        description: "",
        linkInstagram: ""
      },
    }
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeYoutube = this.handleChangeYoutube.bind(this);
    this.handleChangeAudio = this.handleChangeAudio.bind(this);
    this.handleChangeSenderName = this.handleChangeSenderName.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeInstagram = this.handleChangeInstagram.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  componentDidMount() {
    this.renderDropzone()
  }
    
    

    handleCallback = (childData) =>{
     this.setState({ image: childData})
  }

  handleChangeName(event) {    
    this.setState({name: event.target.value});  
  }

  handleChangeSenderName(event) {    
    this.setState({senderName: event.target.value});  
  }

  handleChangeYoutube(event) {    
    this.setState({youtube: event.target.value});  
  }

  handleChangeAudio(event) {    
    this.setState({audio: event.target.value});  
  }

  handleChangeType(event) {    
    this.setState({type: event.target.value});  

  }

  handleChangeDescription(event) {    
    this.setState({description: event.target.value});  
  }

  handleChangeInstagram(event) {    
    this.setState({linkInstagram: event.target.value});  
  }
  // handleChange(event) {    this.setState({value: event.target.value});  }
  async handleSubmit(event) {
    event.preventDefault();
    // const { toastManager } = this.props;
    let youtube = this.state.youtube
    let audio = this.state.audio
    let image = this.state.image
    let data = {}
    switch (this.state.type) {
      case 'Photo':
        data = {
          name: this.state.name,
          description: this.state.description,
          creation_type: this.state.type,
          link_instagram: this.state.linkInstagram,
          youtube: this.state.youtube,
          audio: this.state.linkAudio,
          image: this.state.image,
          image_cover_audio: this.state.image,
          
        }
        // youtube = await ''
        // audio = await ''
        // image = await this.state.image
        this.post(data)
        break;
      case 'Artwork':
        data = {
          name: this.state.name,
          description: this.state.description,
          creation_type: this.state.type,
          link_instagram: this.state.linkInstagram,
          image_cover_audio: this.state.image,
          youtube: this.state.youtube,
          image: this.state.image,
          audio: this.state.linkAudio
        }
        // youtube = await ''
        // audio = await ''
        // image = await this.state.image
        this.post(data)
        break;
      case 'Video':
        if (this.state.youtube.includes("youtube.com/watch?") || this.state.youtube.includes("https://youtu.be/")){
            data = {
              name: this.state.name,
              description: this.state.description,
              link_instagram: this.state.linkInstagram,
              creation_type: this.state.type,
              youtube: this.state.youtube
            }
          this.post(data)
          }else{
            alert("Masukkan hanya link youtube!")
          }
        
        // youtube = await this.state.youtube
        // audio = await ''
        // image = await null
        break;
        case 'Audio':
          if (this.state.audio.includes("//open.spotify.com/track/") || this.state.audio.includes("https://soundcloud.com/")){
            
            data = {
              name: this.state.name,
              description: this.state.description,
              link_instagram: this.state.linkInstagram,
              creation_type: this.state.type,
              image_cover_audio: this.state.image,
              youtube: this.state.youtube,
              image: this.state.image,
              audio: this.state.linkAudio
            }
          this.post(data)
          }else{
            alert("Masukkan hanya link spotify atau soundcloud!")
          }
            
        //   youtube = await ''
        // audio = await this.state.audio
        // image = await this.state.image
        break;
    
    
    }
    
  };
  post(data){
    api.postMethod("creation/add_data", data
      // {
      //   name: this.state.name,
      //   description: this.state.description,
      //   creation_type: this.state.type,
      //   image_cover_audio: image,
      //   youtube: youtube,
      //   image: image,
      //   audio: audio
      // }
      )
      .then((response) => {
        if (response.data.success) {
          alert('Data berhasil dikirim!')
          this.props.history.push("/art");
        } else {
          alert(response.data.error_messages)
        }
      })
      .catch((error) => {
        console.log(error)
        
      });
  }

  redirect(){
    return <Redirect to="/promoc2" />
  }

  renderDropzone(){
    var inputStyle = {
      color: 'white',
      borderColor:'white'
    }
    
    switch (this.state.type) {
      case 'Photo':
        return (
          <div className="mt-5">
            <DropzoneComponent className="mt-3" parentCallback = {this.handleCallback} />
          </div>
        )
      case 'Artwork':
        return (
        <div className="mt-5">
          <DropzoneComponent className="mt-3" parentCallback = {this.handleCallback} />
        </div>
      )
      case 'Video':
        return (
          <div className="mt-2 form-group">
          <label htmlFor="youtube" className="text-white">Link Youtube</label>
          <input
            type="text"
            className="form-control"
            id="youtube"
            name="youtube"
            value={this.state.linkYoutube} onChange={this.handleChangeYoutube}
            style={inputStyle}
          />
        </div>
        )
        case 'Audio':
          return (
            <div>
            <div className="mt-2 form-group">
            <label htmlFor="audio" className="text-white">Link Audio</label>
            <input
              type="text"
              className="form-control"
              id="audio"
              name="audio"
              value={this.state.linkAudio} onChange={this.handleChangeAudio}
             
              style={inputStyle}
            />
          </div>
          <div className="mt-5">
          <DropzoneComponent className="mt-3" parentCallback = {this.handleCallback} />
        </div></div>
          )
      default:
        return (
          <div className="mt-5">
            <DropzoneComponent className="mt-3" parentCallback = {this.handleCallback} />
          </div>
        )
    
    
    }
  }
  render() {
    const { isCopy, listMoreLike } = this.state;
    var inputStyle = {
      color: 'white',
      borderColor:'white'
    }
    const { data } = this.state;
    return (
      <>
      <div>
        <div className="" >
        

          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                <div className="d-flex w-100 auth-page-header mb-4 w-100 d-flex align-items-center justify-content-center">
                  <Link className="d-flex align-items-center justify-content-center" to={"/"}>
                    <img
                      src={process.env.PUBLIC_URL + '/assets/img/logo-vindes.png'}
                      className="submit-art-site-logo-vindes"
                      alt="logo_img"
                    />
                  </Link>
                </div>
                {/* <h3 className="register-box-head">MASUK</h3> */}
                <p class="align-center">Upload Karya</p>

                <p class="align-center text-center">
                  Unggah karya Anda.
                </p>
                <form onSubmit={this.handleSubmit} className="auth-form login-new-form">

                  
                  <div className="mt-2 form-group">
                    <label htmlFor="email" className="text-white">Nama Karya</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={this.state.name} onChange={this.handleChangeName}
                      style={inputStyle}
                    />
                  </div>

                  <div className="mt-2 form-group">
                    <label htmlFor="senderName" className="text-white">Nama Pengirim</label>
                    <input
                      type="text"
                      className="form-control"
                      id="sender-name"
                      name="sender-name"
                      value={this.state.senderName} onChange={this.handleChangeSenderName}
                      style={inputStyle}
                    />
                  </div>

                  <div className="mt-2 form-group">
                    <label htmlFor="linkInstagram" className="text-white">Link Instagram</label>
                    <input
                      type="text"
                      className="form-control"
                      id="link-instagram"
                      name="link-instagram"
                      value={this.state.linkInstagram} onChange={this.handleChangeInstagram}
                      style={inputStyle}
                    />
                    <p style={{fontSize: "14px",marginTop: "2px"}}>* instagram.com/vindes.ig</p>
                  </div>

                  <div className="mt-2 form-group">
                    <label htmlFor="type" className="text-white">Tipe Karya</label>
                    <select type="text"
                      className="form-control"
                      id="type"
                      name="type"
                      value={this.state.type} onChange={this.handleChangeType}
                      style={inputStyle}>
                      <option class="text-option-dark" value="Artwork">Artwork</option>
                      <option class="text-option-dark" value="Video">Video</option>
                      <option class="text-option-dark" value="Audio">Audio</option>
                      <option class="text-option-dark" value="Photo">Photo</option>
                    </select>
                  </div>

                  {this.renderDropzone()}

                  
                  <div className="form-group">
                    <label htmlFor="pwd" className="text-white">Deskripsi</label>
                    <textarea
                      type="textarea"
                      rows="4"
                      
                      className="form-control input-deskripsi-produk"
                      id="description"
                      name="description"
                      onChange={this.handleChangeDescription}
                      value={this.state.description}
                      style={inputStyle}
                    />
                  </div>
                  <button
                    className="btn auth-btn btn-login"
                    id={"submit-art"}
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default SubmitArt;
