import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { apiConstants } from "../../../components/Constant/constants";
import api from "../../../Environment";
import TesCom from "../../LatestLandingPage/TesCom";
import { Nav } from "react-bootstrap";

import { assertTryStatement } from "@babel/types";





class LatestLandingPage extends Component {

  render() {
   

    return (
      <>
      <div className="parallax-container">
        <TesCom />
      </div>
     
      </>
    );
  }
}

export default LatestLandingPage;
