import React, { useEffect, Component } from "react";


import Styled from "styled-components";
import Parallax from "parallax-js";
import PropTypes from "prop-types";

import api from "../../Environment";

import { Link } from "react-router-dom";

// import "./LatestLandingPage.css";
import { setLanguage, translate, t } from "react-multi-lang";
import './Parallax.css'
import configuration from "react-global-configuration";
const $ = window.$;

class TesCom extends Component {
  state = {
    isAuthenticated: this.props.data,
    footer_pages1: [],
    footer_pages2: [],
    loading: true,
    footerList: null,
  };

  componentDidMount() {
    const scene = document.getElementById("scene");
  new Parallax(scene);
  }

  handleChangeLang = ({ currentTarget: input }) => {
    setLanguage(input.value);
    localStorage.setItem("lang", input.value);
    window.location.reload();
  };
  render() {


// useEffect(() => {
//   const scene = document.getElementById("scene");
//   new Parallax(scene);
// });
    return (
      <>
      <div className="parallax-sec" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/background.png'})`,
          }} >
      {/* <div className="overlay">
        
      </div> */}

      <div id="scene"className="parallax-sec align-items-bottom">
        <img key="1" className="toko-box" data-depth="0.1" src={process.env.PUBLIC_URL + '/assets/img/tokobox.png'} alt="rocket" />
        
      </div>
      
     
    </div>
      </>
    );
  }
}

export default translate(TesCom);
