import React from 'react';

const IconArrowDown = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="29" height="48" viewBox="0 0 29 48" fill="none">
<rect width="5.91092" height="33.8535" rx="2.95546" transform="matrix(-0.707107 0.707107 0.707107 0.707107 4.94092 0)" fill="#ED0089"/>
<rect width="5.91092" height="33.8535" rx="2.95546" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.76123 43.8203)" fill="#ED0089"/>
</svg>
);

export default IconArrowDown;
