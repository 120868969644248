import React, { Component } from "react";
import { Link,Redirect   } from "react-router-dom";
import Helper from "../Helper/helper";
import "./SubmitArtikel.scss";
import DropzoneComponent from '../SubmitArt/DropzoneComponent';
import api from "../../Environment";
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';

class SubmitArtikel extends Helper {  

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedOptions: [],
      title: '',
      content: '',
      image: '',
    }
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeContent = this.handleChangeContent.bind(this);
    this.handleChangeOptions = this.handleChangeOptions.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  updateContent(newContent) {
    this.setState({
      content: newContent
    })
  }

  handleChangeContent(evt){
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  handleCallback = (childData) =>{
    this.setState({ image: childData})
  }

  componentDidMount() {
    this.getOptions();
  }

  handleChangeTitle(event) {    
    this.setState({title: event.target.value});  
  }

  handleChangeOptions = selectedOption => {
    this.setState({ selectedOptions: selectedOption });
    console.log(this.state.selectedOptions.map(option => option.value));
  };

  getOptions() {
    api.getMethod('article/tags').then(response => {
      if (response.data.success) {
        this.setState({
          options: response.data.data.map(tag => ({
            value: tag.id,
            label: tag.name,
          })),
        });
      }
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const options = this.state.selectedOptions.map(option => option.value);
    api
      .postMethod('article/add_data', {
        title: this.state.title,
        content: this.state.content,
        image: this.state.image,
        tag_ids: options,
      })
      .then(response => {
        if (response.data.success) {
          alert('Data berhasil dikirim!');
          this.props.history.push('/artikel');
        } else {
          alert(response.data.error_messages);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  redirect(){
    return <Redirect to="/artikel" />
  }
  render() {
    var inputStyle = {
      color: 'white',
      borderColor:'white'
    }
    return (
      <>
      <div>
        <div className="" >
        

          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                <div className="d-flex w-100 auth-page-header mb-4 w-100 d-flex align-items-center justify-content-center">
                  <Link className="d-flex align-items-center justify-content-center" to={"/"}>
                    <img
                      src={process.env.PUBLIC_URL + '/assets/img/logo-vindes.png'}
                      className="submit-art-site-logo-vindes"
                      alt="logo_img"
                    />
                  </Link>
                </div>
                
                <p class="align-center">Upload Artikel</p>

                <p class="align-center text-center">
                  Unggah foto untuk Thumbnail Artikel.
                </p>

                <form onSubmit={this.handleSubmit} className="auth-form login-new-form">

                  <DropzoneComponent parentCallback = {this.handleCallback} />
                  
                  <div className="mt-2 form-group">
                    <label htmlFor="title" className="text-white">Nama Artikel</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      value={this.state.title} onChange={this.handleChangeTitle}
                      style={inputStyle}
                    />
                  </div>

                  <div className="mt-2 form-group">
                    <label htmlFor="email" className="text-white">Tag</label>
                      <Select
                        id="tags"
                        name="tags"
                        options={this.state.options}
                        onChange={this.handleChangeOptions}
                        isMulti
                        placeholder="Pilih Tag Artikel"
                        styles={{
                          placeholder: (base) => ({
                            ...base,
                            color: 'black',
                          }),
                          option: (base) => ({
                            ...base,
                            color: 'black',
                          }),
                        }}
                      />
                  </div>

                  <div className="form-group">
                    <label htmlFor="content" className="text-white">Content</label>
                    <CKEditor
                      id="content"
                      name="content"
                      activeClass="p10"
                      onChange={this.handleChangeContent}
                      content={this.state.content}
                      config={{
                          language: 'en',
                          extraPlugins: 'embed,autoembed,uploadimage,image2',
                          height: 400,

                          filebrowserUploadUrl: '//admin.vindes.id/userApi/article/upload_image',
                          filebrowserUploadMethod: 'form',

                          contentsCss: [
                            'http://cdn.ckeditor.com/4.20.1/full-all/contents.css',
                            'https://ckeditor.com/docs/ckeditor4/4.20.1/examples/assets/css/widgetstyles.css'
                          ],
                          embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                          image2_alignClasses: ['image-align-left', 'image-align-center', 'image-align-right'],
                          image2_disableResizer: true,
                          removeButtons: 'PasteFromWord',
                      }}
                    />
                  </div>

                  <button
                    className="btn auth-btn btn-login"
                    id={"submit-artikel"}
                  >
                    SUBMIT
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default SubmitArtikel;