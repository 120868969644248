import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
 
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};



function DropzoneComponent(props) {
  const [files, setFiles] = useState([]);
  let dropped = 'false'
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file =>{
        props.parentCallback(file);
      }
    )

    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, []);

  const onTrigger = (event) => {
    
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png'
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const thumbs = files.map(file => (
    <div key={file.name} className="d-flex justify-content-center preview-image">
      <img
        src={file.preview}
        alt={file.name}
      />
    </div>
  ));

  // clean up
  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  

  return (
    <section>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div class="d-flex flex-wrap align-items-center justify-content-center w-100 mb-5 upload-image-container">
          <div class="justify-content-center d-flex w-100">
              {!files.length ? (
                <img className="" src={process.env.PUBLIC_URL + '/assets/img/img-default.png'}></img>
              ) : (
                <div> {thumbs}</div>
              )}
              
          </div>
          
          <div>
            <p class="align-center text-center">
              Seret dan lepas gambar atau &nbsp; <a className="browse-link"> jelajahi</a>
            </p>
            <ul className="font-weight-light">
              <li className="font-weight-light">
                Ukuran maximum 500x500, maximum 1MB.
              </li>
              <li className="font-weight-light">
                Hanya Unggah gambar yang anda miliki hak nya.
              </li>
              <li className="font-weight-light">
                Foto clean tanpa design atau element visual.
              </li>
            </ul>
          </div>
        </div>
        
      </div>
     
    </section>
  )
}

export default DropzoneComponent;