import React, { Component } from "react";
import "./karya.scoped.css";
import { apiConstants } from "../../components/Constant/constants";

import api from "../../Environment";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { faHeart, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import MyModalComponent from "./Modal";
import SliderVindes from "../Slider/SliderVindes";
// import Navbar from "../Navbar/Navbar";
import UserHeader from "../Layouts/SubLayout/UserHeader";
//new
// import ContentModal from "../Modals/ContentModal/ContentModal";
import AOS from "aos";
import "aos/dist/aos.css";
import getYouTubeID from "get-youtube-id";
import ButtonFloatingArt from "./ButtonFloatingArt";
import ArtModal from "../Modals/ArtModal/ArtModal";
import {FacebookShareButton,} from "react-share";
let userId = localStorage.getItem("userId");
let accessToken = localStorage.getItem("accessToken");



class Karya extends Component {
  state = {
    HomeSettings: [],
    loading: true,
    loadingData: true,
    faqData: null,
    videoList: null,
    loading: true,
    skipCount: 0,
    loadMoreButtonDisable: false,
    loadingContent: null,
    mainData: null,
    isHidden: "hidden",
    isActive: "hidden",
    currentImage: "fdfasd",
    selectedData: {},
    pusing: "",
    showNavbar: false,
    creations: [],
    dataPropsDetailModalArt:null,
    page: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      body: "",
      data: [],
      categoryCreation: [
        {
          active: true,
          name: "All content",
        },
        {
          active: false,
          name: "Artwork",
        },
        {
          active: false,
          name: "Audio",
        },
        {
          active: false,
          name: "Video",
        },
        {
          active: false,
          name: "Photo",
        },
      ],
      isActiveCategory: "All content",
      slider: [],
      // isCopy: false
    };
  }

  getInitialState() {
    return { page: 0 };
  }

  toggleSidenav() {
    // if (this.state.isActive === "hidden"){
    //   this.setState(isActive: "show")
    // }
    this.setState(
      this.state.isHidden === "hidden"
        ? { isHidden: "show" }
        : { isHidden: "hidden" }
    );
    this.setState(
      this.state.isHidden === "hidden"
        ? { isActive: "active" }
        : { isActive: "" }
    );
  }

  handleShow = (image) => {
    // const image = "this.state.currentImage"
    
    this.setState({
      show: true,
      title: "Group People",
      body: "Hi, find my group details",
      data: image,
    });
  };

  handleClose = (fromModal) => {
    this.setState({
      show: false,
    });
  };

 

  loadMore = (event) => {

    api.postMethod("creations/v2/list", { category: this.state.isActiveCategory,page: this.state.page+1}).then((response) => {
      if (response.data.success) {
        this.setState({
          creations: [...this.state.creations,...response.data.data],
          page: this.state.page+1
        });
      }
    });
  };

  

  

  async fetchConfig() {
    const response = await fetch(apiConstants.homeSettingsUrl);
    const homeResonse = await response.json();

    this.setState({
      loading: false,
      HomeSettings: homeResonse.data,
    });
  }

  async getFaqs() {
    api.getMethod("faqs/list").then((response) => {
      if (response.data.success) {
        this.setState({
          faqData: response.data.data,
          loadingData: false,
        });
      } else {
      }
    });
  }

  async getCreations(event) {
    api.postMethod("creations/v2/list", { category: event, page: this.state.page}).then((response) => {
      if (response.data.success) {
        this.setState({
          creations: response.data.data,
          page: this.state.page+1
        });
      }
    });
  }
  async switchIsActiveCategory(event) {
    await this.setState({
      isActiveCategory: event,
      page: 0
    });
    await this.getCreations(event);
  }

  async getSlider() {
    await api
      .postMethod("sliders/list", {page: "Program"})
      .then(async (response) => {       
        await this.setState({
          slider: response.data.data,
        });
      })
      .catch(function(error) {});
  }

  async componentDidMount() {
    await this.setState({page:1})
    await window.scrollTo(0, 0);
    document.getElementById("footer").style.display = await 'block'
    window.addEventListener("scroll", this.handleScroll, true);
    this.fetchConfig();
    this.getFaqs();
    this.getCreations();
    this.getSlider();
    let inputData;
    let apiURL;
    await AOS.init();
    this.showDetailLink();
    this.sharedLinkAction();
  }

  async componentDidUpdate() {
    await AOS.refresh();
  }

  

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
        // this.setState({page: this.state.page+1})
        this.loadMore()
      }
    }

  // showModalCopyLink = () => {
  //   navigator.clipboard.writeText(window.location.href);
  //   this.setState({isCopy: true});
  //   setTimeout(() => {
  //     this.setState({isCopy: false});
  //   }, 1000);
  // }

  showDetailLink = () => {
    document.getElementById("header").style.zIndex = 1000;
    try {
      this.getDetailCreation(this.props.location.creation.id );
    } catch (error) {
      
    }
    
    
  }

  sharedLinkAction(){
    document.getElementById("header").style.zIndex = 1000;
    if (this.props.match.params.id !== null){
      this.getDetailCreation(this.props.match.params.id );
    }
    
  }
  async getDetailCreation(creation_id) {
    api.postMethod("creations/detail", { creation_id: creation_id, user_id: localStorage.getItem("userId") !== '58'? userId: '' }).then((response) => {
      if(response.data.success) {
        this.setState({
          show: true,
          dataPropsDetailModalArt: response.data.data
        })
      }
    });
  }

  goDetailModalArt = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailCreation(self.id);
  }

  onHideModalArt() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ show: false });
  }

  prefixParentChangeDetailModalArt = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailCreation(self.id);
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false });
    let result = null;
    const {
      loading,
      HomeSettings,
      loadingData,
      faqData,
      categoryCreation,
      isActiveCategory,
      slider,
      showNavbar,
      dataPropsDetailModalArt
    } = this.state;
    function YouTubeGetID(url) {
      var ID = "";
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
      } else {
        ID = url;
      }
      return ID;
    }
    const likeCreation = (creation_id) => {
      if(userId && accessToken && localStorage.getItem("userId") !== 58 && localStorage.getItem("accessToken") !== '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi') {
        api.postMethod("creation/like", { user_id:userId, creation_id:creation_id}).then((response) => {
          if(response.data.data) {
            this.setState({
              dataPropsDetailModalArt: response.data.data
            });
          }
        });
      }else {
        this.props.history.push("/login");

      }
    }
    return (
      <>
        <UserHeader activeNav="ART" showNavbar={showNavbar} />
        <div className="section-wrapper-art-page">
          {/* <div className="section-wrapper-slider">
          <SliderVindes items={slider}/>
          </div> */}
          <div className="section-wrapper-art">
            <section className="filter-container-creation-section-wrapper">
            <div className="d-flex filter-container-creation-section">
            <div className="mr-auto">
            <div className="d-flex">
                {categoryCreation.map((key, index) => (
                  <button 
                    key={`${index}`}
                    className={
                      isActiveCategory === key.name
                        ? "btn badge-creation-section-active"
                        : "btn badge-creation-section"
                    }
                    id={"filter-"+key.name}
                    onClick={() => this.switchIsActiveCategory(key.name)}
                  >
                    {key.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
            </section>

            {/* <MyModalComponent
            show={this.state.show}
            title={this.state.title}
            body={this.state.body}
            data={this.state.data}
            onClick={this.handleClose}
            showModalCopyLink={this.showModalCopyLink}
            onHide={this.handleClose}
            /> */}
            <ArtModal
            show={this.state.show}
            dataPropsDetailModalArt={dataPropsDetailModalArt}
            onHide={() => this.onHideModalArt()}
            likeCreation = {likeCreation}
            prefixParentChangeDetailModalArt={this.prefixParentChangeDetailModalArt}
            >

            </ArtModal>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry>
                {this.state.creations &&
                  this.state.creations.map((creation, i) => (
                    <div
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      className="gallery-item-container"
                      key={i}
                      onClick={() => this.goDetailModalArt(creation)}
                      id={creation.slug}
                    >
                      {creation.creation_type === "Video" && (
                        <div className="video-item">
                          <img
                            key={i}
                            src={
                              "https://img.youtube.com/vi/" +
                              getYouTubeID(creation.link_youtube) +
                              "/sddefault.jpg"
                            }
                            style={{ width: "100%", display: "block" }}
                            alt=""
                            id={creation.slug}
                          />
                          <div className=" d-flex justify-content-center align-items-center overlay-video">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/play-white.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                      <div className="gallery-item-wrapper">
                        {creation.creation_type === "Audio"? (
                        <div>
                          <div className=" overlay-audio align-items-center justify-content-center">
                          <i className="fa fa-music audio-icon"></i>
                          {/* <audio controls disabled src="" />
                          <div className="overlay-audio" /> */}
                        </div>
                        {creation.image_cover_audio ? 
                        <img
                          className="gallery-item"
                          key={i}
                          src={creation.image_cover_audio}
                          style={{ width: "100%", display: "block" }}
                          alt=""
                          id={creation.slug}
                        />
                        
                        : 
                        creation.thumbnail_image ? 
                        <img
                          className="gallery-item"
                          key={i}
                          src={creation.thumbnail_image}
                          style={{ width: "100%", display: "block" }}
                          alt=""
                          id={creation.slug}
                        />: 
                        creation.image ? 
                        <img
                          className="gallery-item"
                          key={i}
                          src={creation.image}
                          style={{ width: "100%", display: "block" }}
                          alt=""
                          id={creation.slug}
                        />:
                        <div
                          className="image-placeholder-art"
                          
                          style={{ width: "100%", display: "block" }}
                          alt=""
                          id={creation.slug}
                        />
                        }
                        
                        </div>
                        
                      ):creation.thumbnail_image ?
                        <img
                        className="gallery-item"
                        key={i}
                        src={creation.thumbnail_image}
                        style={{ width: "100%", display: "block" }}
                        alt=""
                        id={creation.slug}
                      />                        
                        :<img
                        className="gallery-item"
                        key={i}
                        src={creation.image}
                        style={{ width: "100%", display: "block" }}
                        alt=""
                        id={creation.slug}
                      />
                      }

                      
                      </div>
                      
                      <div className="d-flex justify-content-between section-name-countgallery">
                        <strong>{creation.sender_name}</strong>
                        {/* <div className="count-gallery-item">
                          <FontAwesomeIcon icon={faHeart} />
                          <strong> 45 </strong>
                          <FontAwesomeIcon icon={faEye} />
                          <strong> 235</strong>
                        </div> */}
                      </div>
                    </div>
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
          <ButtonFloatingArt link="/submit-art" name="ADD ART"></ButtonFloatingArt>
        </div>
        <div className="gap-padding-bottom" />
        {/* { this.state.isCopy && <div className="modal-copied">Link Copied</div> } */}
      </>
    );
  }
}

export default Karya;
