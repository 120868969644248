import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import api from "../../Environment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./FeaturedEventSlider.scss"
import ContentEventModal from "../Modals/ContentEventModal/ContentEventModal";

// import "./LatestLandingPage.css";
const $ = window.$;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="next-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="prev-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-left"></i>
    </div>
  );
}




class FeturedEventSlider extends Component {
  constructor(props) {
    super(props);
}
  state = {
    loading: true,
    showModaldetailEvent: false,
    dataPropsDetailEvent: [],
  };

  async getDetailEvent(id) {

    api.postMethod("events/detail", { id: id}).then((response) => {
      if(response.data.success) {
        this.setState({
          showModaldetailEvent: true,
          dataPropsDetailEvent: response.data.data
        })
      }
    });
    
  }

  detailEvent(params) {
    document.getElementById("header").style.zIndex = 1;
    this.setState({
      showModaldetailEvent: true,
      dataPropsDetailEvent: params,
    });
  }
  
  onHideModalEvent() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ showModaldetailEvent: false });
  }

  renderImage(featuredEvent, i){
      return(
        <div className="featured-event" onClick={() => this.detailEvent(featuredEvent)}>
            <div className="fe-list-event">
            <div
                className="fe-list-event-thum"
                style={{
                backgroundImage: `url(${featuredEvent.image})`,
                }}
            />
            </div>
            <div className="fe-content">
                <div className="fe-list-title">{featuredEvent.event_name}</div>
                
                <div className="row">
                    <div className="col-3"><span className="fas fa-calendar icon"></span></div>

                    {featuredEvent.event_date_end ? 
                    <div className="col-9"><div className="fe-list-title-small">{featuredEvent.event_date} - {featuredEvent.event_date_end}</div></div> 
                    :                     
                    <div className="col-9"><div className="fe-list-title-small">{featuredEvent.event_date}</div></div>
                    }
                </div>
                <div className="row">
                    <div className="col-3"><span className="fas fa-map-marker-alt icon"></span></div>
                    <div className="col-9"><div className="fe-list-title-small">{featuredEvent.location}</div></div>
                </div>
            </div>
            <div className="fe-footer">
                <div className="fe-list-title">{featuredEvent.event_organizer}</div>
            </div>
        </div>
      )
    }
 
  render() {
    let items = ['',''];
    items = this.props.items
    // console.log('testprops'+Array.isArray(items))
    const settings = {
    //   dots: true,
      infinite: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 7,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    const settingsMobile = {
    //   dots: true,
      infinite: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    const {
        showModaldetailEvent,
        dataPropsDetailEvent,
      } = this.state;
    return (
      <>
      
      <div className="landing-slider-desktop-wrapper">
        <Slider  {...settings}>
        {
        items.map((featuredEvent, i) => (
            this.renderImage(featuredEvent, i)
          ))}
        </Slider>
      </div>
      
      <div className="landing-slider-mobile-wrapper">
      <Slider  {...settingsMobile}>
        {
        items.map((featuredEvent, i) => (
          this.renderImage(featuredEvent, i)
          ))}
        </Slider>
      </div>
       
      <ContentEventModal
          show={showModaldetailEvent}
          dataPropsDetailEvent={dataPropsDetailEvent}
          onHide={() => this.onHideModalEvent()}
        />
      </>
    );
  }
}

export default FeturedEventSlider;
