import React, { Component } from "react";
import UserHeader from "../Layouts/SubLayout/UserHeader";
import ContentEventModal from "../Modals/ContentEventModal/ContentEventModal";
import api from "../../Environment";
import SliderVindes from "../Slider/SliderVindes"
import FeturedEventSlider from "./FeaturedEventSlider";
import "./events.scss";
import ButtonFloatingArt from "../Karya/ButtonFloatingArt";
import { ResponsiveEmbed } from "react-bootstrap";
import { Link } from "react-router-dom";
class Event extends Component {
  state = {
    showModaldetailEvent: false,
    dataPropsDetailEvent: [],

    categoryEvent: [],
    isActiveCategory: "All content",
    listEvent: [],
    listFeaturedEvent: [],
    loading: true,
    errorHandle: 0,
  };
  
  async getDetailEvent(creation_id) {

    api.postMethod("creations/detail", { creation_id: creation_id }).then((response) => {
      if(response.data.success) {
        this.setState({
          show: true,
          dataPropsDetailModalArt: response.data.data
        })
      }
    });
  }

  goDetailModalPromo = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailEvent(self.id);
  }

  detailEvent(params) {
    document.getElementById("header").style.zIndex = 1;
    this.setState({
      showModaldetailEvent: true,
      dataPropsDetailEvent: params,
    });
  }

  onHideModalEvent() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ showModaldetailEvent: false });
  }

  async fecthApiEvent() {
    await api
      .postMethod("events/v2/list")
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            loading: false,
            listEvent: response.data.data.upcoming_event,
            listFeaturedEvent: response.data.data.feature_event,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  // async fecthApiFeaturedEvent() {
  //   await api
  //   .postMethod("events/v2/list")
  //     .then(async (response) => {
  //       if (response.data.success === true) {
  //         this.setState({
  //           loading: false,
  //           listFeaturedEvent: response.data.data.feature_event,
  //         });
  //       } else {
  //         let errorHandle = 1;
  //         this.setState({ errorHandle });
  //       }
  //     })
  //     .catch(function(error) {});
  // }

  renderListEvent = (listDataSelf, index) => {
    return (
    <React.Fragment key={index}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 events" onClick={() => this.detailEvent(listDataSelf)}>
        <div className="section-list-event">
          <div
            className="item-list-event-thum"
            style={{
              backgroundImage: `url(${listDataSelf.image})`,
            }}
          />
        </div>
        <div className="section-content">
          <div className="item-list-title">{listDataSelf.event_name}</div>
          <div className="item-list-title-small"><span className="fas fa-calendar icon"></span>{listDataSelf.event_date}</div>
          <div className="item-list-title-small"><span className="fas fa-map-marker-alt icon"></span>{listDataSelf.location}</div>
        </div>
        <div className="section-footer">
          <div className="item-list-title">{listDataSelf.event_organizer}</div>
        </div>
      </div>
    </React.Fragment>
    )
  }

  async componentDidMount() {
    await window.scrollTo(0, 0);
    document.getElementById("footer").style.display = await 'block'
    await this.fecthApiEvent();
    // await this.fecthApiFeaturedEvent();
    this.showDetailLink()
    this.getSlider()
    this.sharedLinkAction()
  }

  sharedLinkAction(){
    document.getElementById("header").style.zIndex = 1000;
    if (this.props.match.params.id !== null){
      console.log('wahaha:'+this.props.match.params.id)
      this.getDetailEvent(this.props.match.params.id );
    }
    
  }
  async getDetailEvent(id) {

    api.postMethod("events/detail", { id: id}).then((response) => {
      if(response.data.success) {
        this.setState({
          showModaldetailEvent: true,
          dataPropsDetailEvent: response.data.data
        })
      }
    });
    
  }

  

  async getSlider() {
    await api
      .postMethod("sliders/list", {page: "Home"})
      .then(async (response) => {
        let arrayImages = []
       
        await this.setState({
          slider: response.data.data,
          loadingProgram: await false
        });
        // console.log('inilah dia slider:'+ response.data.data)
        
      })
      .catch(function(error) {});
  }

  showDetailLink = ()=>{
    if(this.props.location.creation){
      this.detailEvent(this.props.location.creation)
    }
    
  }
  async switchIsActiveCategory(event) {
    this.setState({
      isActiveCategory: event,
    });
    await this.fecthApiEvent(event);
  }

  render() {
    const {
      categoryEvent,
      isActiveCategory,
      showModaldetailEvent,
      dataPropsDetailEvent,
      listEvent,
      listFeaturedEvent,
      loading,
    } = this.state;
    return (
      <>
        <UserHeader activeNav="EVENT" />
        {/* <div className="slider-event">
          <SliderVindes items={this.state.slider}></SliderVindes>
        </div> */}

        <section className="section-featured-event">
          <h3 className="mt-5">Feature Events</h3>
          <FeturedEventSlider items={listFeaturedEvent}></FeturedEventSlider>
        </section>
        
        
        <section className="section-wrapper-event">

          <h3 className="mt-5">Upcoming Events</h3>
          <div className="row">
            {loading
              ? ""
              : listEvent.map((listData, index) =>
                  listData.length === 0 ? "" : this.renderListEvent(listData, index)
                )}
          </div>

          <div className="section-btn">
            <Link to={{
                      pathname: `/event/upcoming`,
                      state: {
                        type_event: 'upcoming'
                      }
                  }}>
              <button className="btn btn-all-events">All Events</button>
            </Link>
          </div>

                   
        </section>

        <section className="section-past-event">
            <Link to={{
                      pathname: `/event/past`,
                      state: {
                        type_event: 'past'
                      }
                  }}>
              <div className="row">
              <div className="col-md-6"><div className="past-event-title">Past Events</div></div>
              <div className="col-md-6"><div className="fas fa-angle-right past-event-arrow"></div></div>
            </div>
            </Link>
        </section>

        <div className="section-button-download">
          <div style={{ color: "white" }} className="text-center"><strong>Download Sekarang</strong></div>
          <div className="text-center">
            <a target="_blank" href="https://www.apple.com/app-store/">
              <img className="img-store" src={process.env.PUBLIC_URL + '/assets/img/appstore.png'} alt="" />
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=id.vindes">
              <img className="img-store" src={process.env.PUBLIC_URL + '/assets/img/playstore.png'} alt="" />
            </a>
          </div>
        </div>

        <div className="gap-padding-bottom" />
        <ContentEventModal
          show={showModaldetailEvent}
          dataPropsDetailEvent={dataPropsDetailEvent}
          onHide={() => this.onHideModalEvent()}
        />
      </>
    );
  }
}

export default Event;
