import React, { Component } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";
import './footer.scss'

import {
    setTranslations,
    setDefaultLanguage,
    setLanguage,
    translate,
    getLanguage
} from "react-multi-lang";
import en from "../../translation/en.json";
import pt from "../../translation/pt.json";

import configuration from "react-global-configuration";

const $ = window.$;

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: this.props.data,
            footer_pages1: [],
            footer_pages2: [],
            loading: true,
            footerList: null
        };
    }

    componentDidMount() {
        api.getMethod("pages/list")
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        loading: false,
                        footerList: response.data.data
                    });
                } else {
                }
            })
            .catch(function(error) {});

        // var footerHeight = $("#footer").outerHeight();
        // var deviceheight = $(window).outerHeight();
        // var contentheight = deviceheight - footerHeight - 66;
        // $(".main-sec-content").css("min-height", contentheight);

        // $(".bottom-height").height(footerHeight);
        // Call api function

        if (configuration.get("configData.footer_pages1")) {
            this.setState({
                footer_pages1: configuration.get("configData.footer_pages1")
            });
        }
        if (configuration.get("configData.footer_pages2")) {
            this.setState({
                footer_pages2: configuration.get("configData.footer_pages2")
            });
        }
    }

    handleChangeLang = ({ currentTarget: input }) => {
        console.log(input.value);
        setLanguage(input.value);
        localStorage.setItem("lang", input.value);
        window.location.reload();
    };

    render() {
        const { t } = this.props;
        const { loading, footerList } = this.state;
        return (
            <div className="main-footer-sec-content" >
                <div className="bottom-height"></div>
                <div className="footer " id="footer" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bgfooter.jpg`}}>
                    <div className="">
                        <div className="d-flex site-footer align-items-center justify-content-center">
                            <div className="">
                                <div className="footer-link d-flex align-items-center">
                                    {loading
                                        ? t("loading")
                                        : footerList.length > 0
                                        ? footerList.map(
                                              (static_page, index) => (
                                                  <div
                                                      className="footer-link-list"
                                                      key={`page1${index}`}
                                                  >
                                                      <Link
                                                            to={{
                                                                pathname: `/page/${static_page.unique_id}`,
                                                                state: {
                                                                    unique_id: static_page.unique_id
                                                                }
                                                            }}
                                                        >
                                                            {static_page.title}
                                                        </Link>
                                                  </div>
                                              )
                                          )
                                        : ""}
                                </div>
                                <div>
                                <ul className="navbar-nav d-flex flex-row justify-content-center align-items-center">
                                <li className="nav-item">
                                    <a href="https://twitter.com/vindestwitt" target="_blank" className="button-sosmed">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/twitter-white.png'} alt="" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://vt.tiktok.com/ZGJSM4P5o/" target="_blank" className="button-sosmed">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/tiktok-white.png'} alt="" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://www.instagram.com/vindes.ig/" target="_blank"className="button-sosmed">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/instagram-white.png'} alt="" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://www.youtube.com/vindes" target="_blank" className="button-sosmed">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/youtube-white.png'} alt="" />
                                    </a>
                                </li>
                                </ul>
                                
                                </div>
                                <div style={{color: "white"}} className="text-center mt-3">Copyright {new Date().getFullYear()} Vindes | All right reserved.</div>
                                {/* <div style={{color: "white"}} className="text-center mt-3"><strong>Download Sekarang</strong></div>
                                <div className="text-center">
                                    <a target="_blank" href="https://www.apple.com/app-store/">
                                        <img className="img-store" src={process.env.PUBLIC_URL + '/assets/img/appstore.png'} alt="" />
                                    </a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=id.vindes">
                                        <img className="img-store" src={process.env.PUBLIC_URL + '/assets/img/playstore.png'} alt="" />
                                    </a>
                                </div> */}
                                
                            </div>
            
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default translate(Footer);
