import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import api from "../../Environment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./Slider.scoped.css"

// import "./LatestLandingPage.css";
const $ = window.$;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="next-arrow-banner"
      className="arrow-banner-right"
      onClick={onClick}
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="prev-arrow-banner"
      className="arrow-banner-left"
      onClick={onClick}
    >
      <i class="fas fa-chevron-left"></i>
    </div>
  );
}


class SliderVindes extends Component {
  state = {
    isAuthenticated: this.props.data,
    footer_pages1: [],
    
    footer_pages2: [],
    loading: true,
    footerList: null,
  };

  componentDidMount() {
    
  }

  renderSlider(){
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    if(this.props.items && this.props.items[0]){
      if(this.props.items.length < 2){
        return(
          <a id={"link-slider"} href={this.props.items[0].hyperlink} >
            <div style={{backgroundColor: '#ECCC2F', backgroundImage: `url(${this.props.items[0].slider_image})`}} className="d-flex justify-content-center align-items-center slider-content">
          
            </div>
          </a>
        )
      }else{
        return( 
         
        <Slider id="slider-karya" {...settings}>{

        
      this.props.items.map((category, i) => (
         
         <a  id={"link-slider-"+category.id} href={category.hyperlink} >     
          <div id={"slide-"+category.id} style={{backgroundColor: '#ECCC2F', backgroundImage: `url(${category.slider_image})`}} className="d-flex justify-content-center align-items-center slider-content">
          
          </div>
        </a>
        
        ))}
        </Slider>
        )
      }
      
    }
  }

 
  render() {
    
    return (
      <>
       
        {
         
              
          this.renderSlider()
              
          
        
        }
        
        

      </>
    );
  }
}

export default SliderVindes;
