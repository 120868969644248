import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import getYouTubeID from "get-youtube-id";
import "./ArtModal.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {FacebookShareButton, TwitterShareButton,  TwitterIcon,   WhatsappShareButton, WhatsappIcon, FacebookIcon,} from "react-share";
import { SocialIcon } from 'react-social-icons';
class ArtModal extends Component {  
    state = {
      isCopy: false,
      
    }

  copyLinkArt(link) {
    navigator.clipboard.writeText((window.location.href+"/"+link).replace('art//','art/'));
    this.setState({ isCopy: true });
    setTimeout(() => {
      this.setState({ isCopy: false });
    }, 1000);
  }

  async openInstagram(link){
    await this.copyLinkArt(link)
    var win = window.open('instagram://story-camera');
  }

  async openLinkInstagram(link){

    if(!link.includes('http') ){
     
      link = 'https://' + link
    }

    var win = window.open(link);
  }

  generateLinkAudio(link_audio) {
    let sound_cloud = "https://w.soundcloud.com/player/?url="
    if(link_audio && link_audio.includes('soundcloud')) {
      return sound_cloud + link_audio;
    }else if (link_audio && link_audio.includes('spotify')) {
      return link_audio.replace('/track/', '/embed/track/');
    } else {
      return sound_cloud
    }
    
  }
  changeDetailModalArt(self){
    document.getElementById('prefixModalWrapper').scroll(
      {
        top:0,
        behavior:'smooth'
      });
    this.props.prefixParentChangeDetailModalArt(self)
  }

  renderComponentVideoArt = (videoData, index) => {
    return (
      <React.Fragment key={index}>
        <section className="wrapper-video-item-more-like-modal">
          <img
            className="video-item-more-like-modal-cover-video"
            key={index}
            src={
              "https://www.youtube.com/embed/" + getYouTubeID(videoData.link_youtube)
            }
            style={{ width: "100%", display: "block" }}
            alt=""
          />
          <div className="d-flex justify-content-center align-items-center video-item-more-like-modal-overlay">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/play-white.png"}
              alt=""
            />
          </div>
        </section>
        <div className="d-flex justify-content-between wrapper-item-title-love-more-like-modal">
          <div className="item-username-more-like">{videoData.sender_name}</div>
          <div className="item-count-more-like">
            <i className="fa fa-heart icon-more-like-count"> 45</i>
            <i className="fa fa-eye icon-more-like-count"> 235</i>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderComponentAudioArt = (audioData, index) => {
    return (
      <React.Fragment key={index}>
        <section>
          <div className="d-flex align-items-center justify-content-center wrapper-audio-item-more-like-modal">
            <audio controls disabled src="" />
            <div className="audio-item-more-like-modal-overlay" />
          </div>
          <div className="d-flex justify-content-between wrapper-item-title-love-more-like-modal">
            <div className="item-username-more-like">Michael Romeo</div>
            <div className="item-count-more-like">
              <i className="fa fa-heart icon-more-like-count"> 45</i>
              <i className="fa fa-eye icon-more-like-count"> 235</i>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  };

  renderComponentArtWorkArt = (artworkData, index) => {
    return (
      <React.Fragment key={index}>
        <section className="wrapper-item-image-more-like-modal">
          <img
            key={index}
            src={artworkData.image}
            alt=""
            onClick={() => this.changeDetailModalArt(artworkData)}
          />
          <p class="item-image-title-more-like-modal">{artworkData.name}</p>
          </section>
          <div className="d-flex justify-content-between wrapper-item-title-love-more-like-modal">
            <div className="item-username-more-like">{artworkData.sender_name}</div>
            {/* <div className="item-count-more-like">
              <i className="fa fa-heart icon-more-like-count"> 45</i>
              <i className="fa fa-eye icon-more-like-count"> 235</i>
            </div> */}
          </div>
      </React.Fragment>
    );
  };

  render() {
    const { isCopy, listMoreLike } = this.state;

    return (
      <Modal
        keyboard={false}
        className="modal-art-wrapper"
        show={this.props.show}
        onHide={() => this.props.onHide(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body  id="prefixModalWrapper">
          {this.props.dataPropsDetailModalArt ? (
            <section className="wrapper-art">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  {this.props.dataPropsDetailModalArt.creation_type ===
                  "Video" ? (
                    <iframe
                      className="wrapper-video-art"
                      src={
                        "https://www.youtube.com/embed/" +
                        getYouTubeID(
                          this.props.dataPropsDetailModalArt.link_youtube
                        )
                      }
                      alt={this.props.dataPropsDetailModalArt.name}
                      title={this.props.dataPropsDetailModalArt.name}
                      style={{height:'40vh'}}
                      allow="fullscreen;"
                    />
                  ) : this.props.dataPropsDetailModalArt.creation_type ===
                    "Audio" ? (
                    <iframe
                      className="wrapper-audio-art"
                      src={this.generateLinkAudio(this.props.dataPropsDetailModalArt.link_audio)}
                      scrolling="no"
                      frameborder="no"
                      allow="autoplay"
                      alt={this.props.dataPropsDetailModalArt.name}
                      title={this.props.dataPropsDetailModalArt.name}
                    />
                  ) : (
                    <div className="wrapper-image-art">
                      <img
                        src={this.props.dataPropsDetailModalArt.image}
                        alt={this.props.dataPropsDetailModalArt.name}
                      />
                      {/* <div className="icon-image-art-zoom">
                        <i className="fa fa-search" />
                      </div> */}
                    </div>
                  )}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-conten-art">
                    <div className="title-wrapper-art">
                      {this.props.dataPropsDetailModalArt.name}
                    </div>

                    <div className="wrapper-flex-art-button-text">
                      
                      <div className="item-desktop">
                      
                      <div className="d-flex justify-item-between">

                      <div className="date-art-button mr-3">
                        {this.props.dataPropsDetailModalArt.created_at}
                      </div>
                        <WhatsappShareButton
                          url={(window.location.href+'/'+this.props.dataPropsDetailModalArt.id).replace('art//','art/')}
                          quote={this.props.dataPropsDetailModalArt.name +" - "+ this.props.dataPropsDetailModalArt
                          .description.replace(/(<([^>]+)>)/gi, "")}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <WhatsappIcon size={40} round={true} />
                          </a>
                        </WhatsappShareButton>
                        <div
                          className="wrapper-button-art-share mr-2"
                          onClick={() => this.openInstagram(this.props.dataPropsDetailModalArt.id)}
                        >
                          <div className="container-button-art">
                            <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                            {/* <div className="child-text-button-art">SHARE</div> */}
                          </div>
                        </div>
                        <FacebookShareButton
                          url={(window.location.href+'/'+this.props.dataPropsDetailModalArt.id).replace('art//','art/')}
                          quote={this.props.dataPropsDetailModalArt.name +" - "+ this.props.dataPropsDetailModalArt
                          .description.replace(/(<([^>]+)>)/gi, "")}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <FacebookIcon size={40} round={true} />
                          </a>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={(window.location.href+'/'+this.props.dataPropsDetailModalArt.id).replace('art//','art/')}
                          quote={this.props.dataPropsDetailModalArt.name +" - "+ this.props.dataPropsDetailModalArt
                          .description.replace(/(<([^>]+)>)/gi, "")}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <TwitterIcon size={40} round={true} />
                          </a>
                        </TwitterShareButton>
                        <div
                          className="wrapper-button-art-share mr-2"
                          onClick={() => this.copyLinkArt(this.props.dataPropsDetailModalArt.id)}
                        >
                          <div className="container-button-art">
                            <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                            {/* <div className="child-text-button-art">SHARE</div> */}
                          </div>
                        </div>
                        <div  className={`wrapper-button-art  ${this.props.dataPropsDetailModalArt.is_like ? "art-liked": ""}`} onClick={() => this.props.likeCreation(this.props.dataPropsDetailModalArt.id)}>
                        <div className="container-button-art">
                          <i className="fa fa-heart child-icon-buton-art" />
                          <div className="child-text-button-art">{this.props.dataPropsDetailModalArt.like}</div>
                        </div>
                      </div>
                        </div>
                        
                    </div>

                    <div className="item-mobile ">
                      <div className="d-flex">
                      <div className="date-art-button">
                        {this.props.dataPropsDetailModalArt.created_at}
                      </div>
                      <div className="d-flex ml-5">

                      
                        <WhatsappShareButton
                          url={(window.location.href+'/'+this.props.dataPropsDetailModalArt.id).replace('art//','art/')}
                          quote={this.props.dataPropsDetailModalArt.name +" - "+ this.props.dataPropsDetailModalArt
                          .description.replace(/(<([^>]+)>)/gi, "")}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <WhatsappIcon size={30} round={true} />
                          </a>
                        </WhatsappShareButton>
                        <div
                          className="wrapper-button-art-share mr-2"
                          onClick={() => this.openInstagram(this.props.dataPropsDetailModalArt.id)}
                        >
                          <div className="container-button-art">
                            <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                            {/* <div className="child-text-button-art">SHARE</div> */}
                          </div>
                        </div>
                        <FacebookShareButton
                          url={(window.location.href+'/'+this.props.dataPropsDetailModalArt.id).replace('art//','art/')}
                          quote={this.props.dataPropsDetailModalArt.name +" - "+ this.props.dataPropsDetailModalArt
                          .description.replace(/(<([^>]+)>)/gi, "")}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <FacebookIcon size={30} round={true} />
                          </a>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={(window.location.href+'/'+this.props.dataPropsDetailModalArt.id).replace('art//','art/')}
                          quote={this.props.dataPropsDetailModalArt.name +" - "+ this.props.dataPropsDetailModalArt
                          .description.replace(/(<([^>]+)>)/gi, "")}
                          hashtag="#VinDes"
                          className=" mr-2"
                        >
                          <a href="#" className="">
                            <TwitterIcon size={30} round={true} />
                          </a>
                        </TwitterShareButton>
                        <div
                          className="wrapper-button-art-share mr-2"
                          onClick={() => this.copyLinkArt(this.props.dataPropsDetailModalArt.id)}
                        >
                          <div className="container-button-art">
                            <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                            {/* <div className="child-text-button-art">SHARE</div> */}
                          </div>
                        </div>
                        <div  className={`wrapper-button-art  ${this.props.dataPropsDetailModalArt.is_like ? "art-liked": ""}`} onClick={() => this.props.likeCreation(this.props.dataPropsDetailModalArt.id)}>
                          <div className="container-button-art">
                            <i className="fa fa-heart child-icon-buton-art" />
                            <div className="child-text-button-art">{this.props.dataPropsDetailModalArt.like}</div>
                          </div>
                        </div>
                        </div>
                      </div>
                      </div>
                      
                    </div>

                    <div className="desc-wrapper-art">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.dataPropsDetailModalArt
                            .description,
                        }}
                      />
                      <div className="wrapper-avatar-art-seder">
                        {/* <div className="avatar-art-seder" /> */}
                        <div className="ml-0 avatar-art-seder-user">
                          {this.props.dataPropsDetailModalArt.sender_name}
                        </div>
                      </div>
                      {this.props.dataPropsDetailModalArt.link_instagram && (
                      <div
                      className="wrapper-button-art-share mr-2 mt-4"
                      onClick={() => this.openLinkInstagram(this.props.dataPropsDetailModalArt.link_instagram)}
                    >
                      <div className="container-button-art">
                        <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                        {/* <div className="child-text-button-art">SHARE</div> */}
                      </div>
                    </div>
                    )}
                    </div>
                    <div className="wrapper-suggest-more-like-username">
                      Other creation by{" "}
                      {this.props.dataPropsDetailModalArt.sender_name}
                    </div>
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 2 }}
                    >
                      <Masonry gutter={20}>
                        {this.props.dataPropsDetailModalArt.other_creations && this.props.dataPropsDetailModalArt.other_creations.map((creation, i) => (
                          <section
                            className="wrapper-more-like-art-modal"
                            key={i}
                          >
                            {creation.creation_type === "Video"
                              ? this.renderComponentVideoArt(creation, i)
                              : creation.creation_type === "Audio"
                              ? this.renderComponentAudioArt(creation, i)
                              : creation.creation_type === "Artwork"
                              ? this.renderComponentArtWorkArt(creation, i)
                              : ""}
                          </section>
                        ))}
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {isCopy && <div className="modal-copied">Link Copied</div>}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ArtModal;
