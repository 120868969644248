import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import api from "../../Environment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./LandingSlider.css"

// import "./LatestLandingPage.css";
const $ = window.$;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="next-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      id="prev-arrow-landing"
      onClick={onClick}
    >
      <i class="fas fa-chevron-left"></i>
    </div>
  );
}




class LandingSlider extends Component {
  constructor(props) {
    super(props);
}
  state = {
    isAuthenticated: this.props.data,
    footer_pages1: [],
    
    footer_pages2: [],
    loading: true,
    footerList: null,
    listCategoryAndVideo: []
  };

  componentDidMount() {
    this.fecthApiListHome()
  }

  renderCategories = (videos)=>{
    var indents = [];
    for (let i = 0; i <= 2; i++) {
      let index = 0
      // indents.push(<div className="categories dummies"  >{ videos.videos[index].category_name}</div>)
      // indents.push(<div className="categories dummies"  >{videos}</div>);
    }
    return indents;
     
      
    
    
  }

  renderImage(category, i){
    if(category.product_name){
      return(
        <Link to={{
          pathname:"/merch",
          creation: category
      }} className="list-container-landing-slider">
        <div  key={`${i}`} className="item-landing-slider" style={{
              backgroundImage: `url(${category.product_image})`,
            }}>
        </div>
        </Link>
      )
    }else{
      return(
        <Link to={{
          pathname: "/program/view-all",
          state: {
            url_type: 'URL_TYPE_CATEGORY',
            url_type_id: category.category_id,
            page_type: "HOME",
            title: category.category,
          },
        }} className="list-container-landing-slider">
        <figure>
          <div  key={`${i}`} className="item-landing-slider" style={{
                backgroundImage: `url(${category.category_picture})`,
              }}>
          </div>
          <figcaption className="caption-name">{category.category}</figcaption>
          <figcaption className="caption-count">{category.videos_count} Videos</figcaption>
        </figure>
        </Link>
      )
      
    }
  }

  async fecthApiListHome() {
    await api
      .postMethod("categories_home")
      .then(async (response) => {
        // console.log('inilah dia:'+ response.data.data)
        this.setState({
          listCategoryAndVideo: await response.data.data,
        });
      })
      .catch(function(error) {});
  }

 
  render() {
    // console.log('testprops:'+this.props.items[0].category)
    let items = ['',''];
    items = this.props.items
    // console.log('testprops'+Array.isArray(items))
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    const settingsMobile = {
      dots: true,
      infinite: true,
      speed: 500,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
      
    };
    return (
      <>
      

      <div className="landing-slider-desktop-wrapper">
        <Slider  {...settings}>
        {
        items.map((category, i) => (
            this.renderImage(category, i)
          ))}
        </Slider>
      </div>
      
      <div className="landing-slider-mobile-wrapper">
      <Slider  {...settingsMobile}>
        {
        items.map((category, i) => (
          this.renderImage(category, i)
          ))}
        </Slider>
      </div>
       
     
      </>
    );
  }
}

export default LandingSlider;
