import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import './ArtikelModal.scss';
import { FacebookShareButton, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, FacebookIcon, } from "react-share";

class ArtikelModal extends Component {

    state = {
      isCopy: false,
    };
    
    copyLinkArtikel(link) {
      navigator.clipboard.writeText((window.location.href+"/"+link).replace('artikel//','artikel/'));
      this.setState({ isCopy: true });
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    }

    async openInstagram(link){
      await this.copyLinkArtikel(link)
      var win = window.open('instagram://story-camera');
    }

    render(props) {
        const dataProps = this.props.dataPropsDetailModalArtikel
        const { isCopy } = this.state;
        return (
            <Modal className="modal-artikel-wrapper" show={this.props.show}
            onHide={() => this.props.onHide(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  {dataProps ? (
                      <section id="article">
                        <div className="entry-article">
                          <div className="inner-article">
                            <div className="wrapper-artikel">
                              <div className="title-artikel">{dataProps.title}</div>
                              <div className="published-artikel">{dataProps.creator} - {dataProps.date}</div>
                              <div className="image-artikel"><img src={`${dataProps.thumbnail_image}`} alt="" /></div>
                              <div className="item-mobile" style={{paddingTop: '20px'}}>
                                <div className="d-flex">
                                  <WhatsappShareButton
                                    url={(window.location.href + '/' + dataProps.id).replace('artikel//', 'artikel/')}
                                    quote={dataProps.title + " - " + (dataProps.description && dataProps.description.replace(/(<([^>]+)>)/gi, ""))}
                                    hashtag="#VinDes"
                                    className=" mr-2"
                                  >
                                    <a href="#" className="">
                                      <WhatsappIcon size={30} round={true} />
                                    </a>
                                  </WhatsappShareButton>
                                  <div
                                    className="wrapper-button-art-share mr-2"
                                    onClick={() => this.openInstagram(dataProps.id)}
                                  >
                                    <div className="container-button-art">
                                      <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                                    </div>
                                  </div>
                                  <FacebookShareButton
                                    url={(window.location.href + '/' + dataProps.id).replace('artikel//', 'artikel/')}
                                    quote={dataProps.title + " - " + (dataProps.description && dataProps.description.replace(/(<([^>]+)>)/gi, ""))}
                                    hashtag="#VinDes"
                                    className=" mr-2"
                                  >
                                    <a href="#" className="">
                                      <FacebookIcon size={30} round={true} />
                                    </a>
                                  </FacebookShareButton>
                                  <TwitterShareButton
                                    url={(window.location.href + '/' + dataProps.id).replace('artikel//', 'artikel/')}
                                    quote={dataProps.title + " - " + (dataProps.description && dataProps.description.replace(/(<([^>]+)>)/gi, ""))}
                                    hashtag="#VinDes"
                                    className=" mr-2"
                                  >
                                    <a href="#" className="">
                                      <TwitterIcon size={30} round={true} />
                                    </a>
                                  </TwitterShareButton>
                                  <div
                                    className="wrapper-button-art-share mr-2"
                                    onClick={() => this.copyLinkArtikel(dataProps.id)}
                                  >
                                    <div className="container-button-art">
                                      <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                                    </div>
                                  </div>
                                  <div  className={`wrapper-button-art  ${dataProps.is_like ? "art-liked": ""}`} onClick={() => this.props.likeArticle(dataProps.id)}>
                                  <div className="container-button-art">
                                    <i className="fa fa-heart child-icon-buton-art" />
                                    <div className="child-text-button-art">{dataProps.like}</div>
                                  </div>
                                </div>
                                </div>
                              </div>
                              <div className="content-artikel">
                                <ul className="content-sosmed">
                                  <li>
                                    <WhatsappShareButton
                                      url={(window.location.href + '/' + dataProps.id).replace('artikel//', 'artikel/')}
                                      quote={dataProps.title + " - " + (dataProps.description && dataProps.description.replace(/(<([^>]+)>)/gi, ""))}
                                      hashtag="#VinDes"
                                      className=" mr-2"
                                    >
                                      <a href="#" className="">
                                        <WhatsappIcon size={40} round={true} />
                                      </a>
                                    </WhatsappShareButton>
                                  </li>
                                  <li>
                                    <FacebookShareButton
                                      url={(window.location.href + '/' + dataProps.id).replace('artikel//', 'artikel/')}
                                      quote={dataProps.title + " - " + (dataProps.description && dataProps.description.replace(/(<([^>]+)>)/gi, ""))}
                                      hashtag="#VinDes"
                                      className=" mr-2"
                                    >
                                      <a href="#" className="">
                                        <FacebookIcon size={40} round={true} />
                                      </a>
                                    </FacebookShareButton>
                                  </li>
                                  <li>
                                    <TwitterShareButton
                                      url={(window.location.href + '/' + dataProps.id).replace('artikel//', 'artikel/')}
                                      quote={dataProps.title + " - " + (dataProps.description && dataProps.description.replace(/(<([^>]+)>)/gi, ""))}
                                      hashtag="#VinDes"
                                      className=" mr-2"
                                    >
                                      <a href="#" className="">
                                        <TwitterIcon size={40} round={true} />
                                      </a>
                                    </TwitterShareButton>
                                  </li>
                                  <li>
                                    <div
                                      className="wrapper-button-art-share mr-2"
                                      onClick={() => this.openInstagram(dataProps.id)}
                                    >
                                      <div className="container-button-art">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      className="wrapper-button-art-share mr-2"
                                      onClick={() => this.copyLinkArtikel(dataProps.id)}
                                    >
                                      <div className="container-button-art">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div  className={`wrapper-button-art  ${dataProps.is_like ? "art-liked": ""}`} onClick={() => this.props.likeArticle(dataProps.id)}>
                                      <div className="container-button-art">
                                        <i className="fa fa-heart child-icon-buton-art" />
                                        <div className="child-text-button-art">{dataProps.like}</div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div className="single-artikel" dangerouslySetInnerHTML={{__html: dataProps.content}}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                  ) : ("")}
                  {isCopy && <div className="modal-copied">Link Copied</div>}
                </Modal.Body>
            </Modal>
        )
    };
}

export default ArtikelModal;