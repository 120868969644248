import React, { Component } from "react";
import UserHeader from "../Layouts/SubLayout/UserHeader";
import ContentPromoC2Modal from "../Modals/ContentPromoC2Modal/ContentPromoC2Modal";
import api from "../../Environment";
import SliderVindes from "../Slider/SliderVindes"

import "./promoc2.scss";
import ButtonFloatingArt from "../Karya/ButtonFloatingArt";
import { ResponsiveEmbed } from "react-bootstrap";
class PromoC2 extends Component {
  state = {
    showModalDetailPromoC2: false,
    dataPropsDetailModalC2: [],

    categoryPromoC2: [
      
    ],
    page: 1,
    isActiveCategory: "All content",
    listPromoC2: [],
    loading: true,
    errorHandle: 0,
  };

  
  
  async getDetailPromo(creation_id) {
    api.postMethod("creations/detail", { creation_id: creation_id }).then((response) => {
      if(response.data.success) {
        this.setState({
          show: true,
          dataPropsDetailModalArt: response.data.data
        })
      }
    });
  }

  goDetailModalPromo = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailPromo(self.id);
  }

  detailPromoC2(params) {
    document.getElementById("header").style.zIndex = 1;
    this.setState({
      showModalDetailPromoC2: true,
      dataPropsDetailModalC2: params,
    });
  }

  onHideModalPromoC2() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ showModalDetailPromoC2: false });
  }

  async fecthApiPromoC2(category) {
   
    await api
      .postMethod("umkms/v2/list", {category: this.state.isActiveCategory, page: this.state.page})
      .then(async (response) => {
        if (response.data.success === true) {


          this.setState({ 
            loading: false, 
            listPromoC2: [...this.state.listPromoC2, ...response.data.data] 
          })
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  async filterPromoC2(category) {
   this.setState({page: 1})
    await api
      .postMethod("umkms/v2/list", {category: this.state.isActiveCategory, page: this.state.page})
      .then(async (response) => {
        if (response.data.success === true) {


          this.setState({ 
            loading: false, 
            listPromoC2: response.data.data
          })
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  renderListPromoC2 = (listDataSelf, index) => {
    return (
    <React.Fragment key={index}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <div className="section-list-promoc2">
          {listDataSelf.thumbnail_image ?
          <div
          className="item-list-promoc2-thum"
          style={{
            backgroundImage: `url(${listDataSelf.thumbnail_image})`,
          }}
          /> 
          :<div
          className="item-list-promoc2-thum"
          style={{
            backgroundImage: `url(${listDataSelf.image})`,
          }}
          />}
          
          <div className="item-list-promoc2-content">
            {listDataSelf.thumbnail_image?
            <div
            className="item-list-promoc2-thum-mobile"
            style={{
              backgroundImage: `url(${listDataSelf.thumbnail_image})`,
            }}
            /> 
            :<div
            className="item-list-promoc2-thum-mobile"
            style={{
              backgroundImage: `url(${listDataSelf.image})`,
            }}
            /> }
            
            <div className="item-list-title">{listDataSelf.name}</div>
            {/* <div className="item-list-promoc2-tag">
              {listDataSelf.tags ? 
               listDataSelf.tags.map((key, i) => (
                <div className="badge-promoc2-category-tag">{`#${key}`}</div>
              ))
               : ''}
            </div> */}
            <div className="item-list-desc" dangerouslySetInnerHTML={{ __html:listDataSelf.description }} />
            <button id={"read-more-"+listDataSelf.name} className="btn badge-promoc2-readmore" 
            onClick={() => this.detailPromoC2(listDataSelf)}>Read more</button>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }

  async componentDidMount() {
    await window.scrollTo(0, 0);
    document.getElementById("footer").style.display = await 'block'
    await this.fecthApiPromoC2("");
    this.showDetailLink()
    this.getSlider()
    this.sharedLinkAction()
    this.getCategoryList()
    window.addEventListener('scroll', this.handleScroll, true);
    // this.loadMoreItems(Math.floor(res.payload.data.postLength / 10));
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
        this.setState({page: this.state.page+1})
        this.fecthApiPromoC2()
      }
    }

  sharedLinkAction(){
    document.getElementById("header").style.zIndex = 1000;
    if (this.props.match.params.id !== null){
      this.getDetailPromo(this.props.match.params.id );
    }
    
  }
  async getDetailPromo(creation_id) {

    api.postMethod("umkms/detail", { id: creation_id}).then((response) => {
      if(response.data.success) {
        this.setState({
          showModalDetailPromoC2: true,
          dataPropsDetailModalC2: response.data.data
        })
      }
    });
    
  }

  async getCategoryList(){
    api.getMethod("umkms/category_list").then((response) => {
      
      let arrayCategoryList = response.data.data.map((str) => ({ name: str, active: false}))
      this.setState({categoryPromoC2: arrayCategoryList})
    })
  }
  

  async getSlider() {
    await api
      .postMethod("sliders/list", {page: "PromoC2"})
      .then(async (response) => {
        let arrayImages = []
       
        await this.setState({
          slider: response.data.data,
          loadingProgram: await false
        });
        // console.log('inilah dia slider:'+ response.data.data)
        
      })
      .catch(function(error) {});
  }

  showDetailLink = ()=>{
    if(this.props.location.creation){
      this.detailPromoC2(this.props.location.creation)
    }
    
  }
  async switchIsActiveCategory(event) {
    await this.setState({
      isActiveCategory: event,
    });
    await this.filterPromoC2(event);
  }

  render() {
    const {
      categoryPromoC2,
      isActiveCategory,
      showModalDetailPromoC2,
      dataPropsDetailModalC2,
      listPromoC2,
      loading,
    } = this.state;
    // const listPromoC2 = [
    //   {
    //     image: process.env.PUBLIC_URL + "/assets/img/promo-cafe.png",
    //     title: "The best Coffee Shops and Cafes in Yogyakarta",
    //     desc:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //     tag: ["cafe", "drink", "pastries"],
    //   },
    //   {
    //     image: process.env.PUBLIC_URL + "/assets/img/promo-plants.png",
    //     title: "House Plants With Style",
    //     desc:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //     tag: ["Plants", "monstera", "Variegated"],
    //   },
    //   {
    //     image: process.env.PUBLIC_URL + "/assets/img/promo-motor.png",
    //     title: "Custom Motorcycle Workshop [Lord Drake Kustoms]",
    //     desc:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //     tag: ["Motorbike", "Custom", "Bengkel"],
    //   },
    //   {
    //     image: process.env.PUBLIC_URL + "/assets/img/promo-bike.png",
    //     title: "Nine best bike repair work stands reviewed | Cyclist",
    //     desc:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //     tag: ["BMX", "Roadbike", "bengkel"],
    //   },
    //   {
    //     image: process.env.PUBLIC_URL + "/assets/img/promo-plants.png",
    //     title: "House Plants With Style",
    //     desc:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //     tag: ["Plants", "monstera", "Variegated"],
    //   },
    // ];
    return (
      <>
        <UserHeader activeNav="PROMOC2" />
        {/* <div className="slider-promoc2">
          <SliderVindes items={this.state.slider}></SliderVindes>
        </div> */}
        
        
        <section className="section-wrapper-promoc2">
        <section className="filter-container-creation-section-wrapper">
            <div className="d-flex filter-container-creation-section">
              <div className="mr-auto">
              <div className="d-flex">
                  {this.state.categoryPromoC2.map((key, index) => (
                    <div
                      key={`${index}`}
                      className={
                        isActiveCategory === key.name
                          ? "btn badge-creation-section-active"
                          : "btn badge-creation-section"
                      }
                      onClick={() => this.switchIsActiveCategory(key.name)}
                    >
                      {key.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          {/* <section className="filter-container-promoc2-section-wrapper">
            <div className="d-flex filter-container-promoc2-section">
              {categoryPromoC2.map((key, index) => (
                <div
                  key={`${index}`}
                  className={
                    isActiveCategory === key.name
                      ? "btn badge-promoc2-section-active"
                      : "btn badge-promoc2-section"
                  }>
                  {key.name}
                </div>
              ))}
            </div>
          </section> */}
          <div className="row">
            {loading
              ? ""
              : listPromoC2.map((listData, index) =>
                  listData.length === 0 ? "" : this.renderListPromoC2(listData, index)
                )}
          </div>
          <ButtonFloatingArt link="/submitc2" name="ADD PROMO"></ButtonFloatingArt>
        </section>
        <div className="gap-padding-bottom" />
        <ContentPromoC2Modal
          show={showModalDetailPromoC2}
          dataPropsDetailModalC2={dataPropsDetailModalC2}
          onHide={() => this.onHideModalPromoC2()}
        />
      </>
    );
  }
}

export default PromoC2;
