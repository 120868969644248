import React, { Component } from "react";
import './ButtonFloatingArt.scss';
import { Link } from "react-router-dom";

class ButtonFloatingArt extends Component {
  
  state = {
    isActive:false,
    from: "",
    link:''
  }

  async componentDidMount(){
    
    if (localStorage.getItem("userId")  && localStorage.getItem("accessToken") && localStorage.getItem("userId") !== 58 && localStorage.getItem("accessToken") !== '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi'
    ){
      await this.setState({link:this.props.link})
      
    }else{
      
      this.setState({link:'/login'})
    }
    
   
  }


  toFormArt() {
    if (localStorage.getItem("userId") !== '58' ){
      return true
    }else{
     return false
    }
  }

  render() {
    const {
      isActive
    } = this.state;
    return (
      <>
          <section class="wrapper-button-floating-section">
            <div onClick={() => this.setState({isActive:!this.state.isActive})} className="button-floating-add-icon">
              <i className={`fa ${isActive ? 'fa-times' : 'fa-plus' }`} />
            </div>
            <div className={isActive ? 'button-active-flaoting-text' : 'button-flaoting-text'}>
              {
                this.toFormArt() ? 
                <Link to={this.state.link}>
                <div className="button-floating-add-icon-text">
                <div className="wrapper-button-floating-text">
                  <i className="fa fa-plus icon-btn-flaoting-text" />
                  <div className="btn-flaoting-text-nonicon">{this.props.name}</div>
                </div>
                </div>
              </Link>
                : 
                <Link id={"goto"+this.props.name} to={{
                  pathname:this.state.link,
                  link: this.props.link
                  }} >
                    <div className="button-floating-add-icon-text">
                    <div className="wrapper-button-floating-text">
                      <i className="fa fa-plus icon-btn-flaoting-text" />
                      <div className="btn-flaoting-text-nonicon">{this.props.name}</div>
                    </div>
              </div>
                </Link>
              }
            </div>
          </section>
      </>
    )
  }
}


export default ButtonFloatingArt;