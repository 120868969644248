import React, { useEffect, Component } from "react";


import Styled from "styled-components";
import Parallax from "parallax-js";
import PropTypes from "prop-types";

import api from "../../Environment";

import { Link } from "react-router-dom";
import "./LatestLandingPageResponsive.css";
import "./LatestLandingPage.css";
import { setLanguage, translate, t } from "react-multi-lang";
import './Parallax.scss'
import configuration from "react-global-configuration";
const $ = window.$;

class TesCom extends Component {
  state = {
    isAuthenticated: this.props.data,
    footer_pages1: [],
    footer_pages2: [],
    loading: true,
    footerList: null,
  };

  componentDidMount() {
    const scene = document.getElementById("scene");
    const sceneMobile = document.getElementById("scene-mobile");
  new Parallax(scene);
  new Parallax(sceneMobile);
  }

  handleChangeLang = ({ currentTarget: input }) => {
    setLanguage(input.value);
    localStorage.setItem("lang", input.value);
    window.location.reload();
  };
  render() {


// useEffect(() => {
//   const scene = document.getElementById("scene");
//   new Parallax(scene);
// });
    return (
      <>




      <div className="latest-landing-sec parallax-desktop" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/bg_parallax.jpg'})`,
          }} >
      <div className="overlay-vincen"></div>
      <div className="overlay-desta"></div>


      <div id="scene"className="latest-landing-sec align-items-center">
        {/* <img key="1" className="vindes-box" data-depth="0.3" src={process.env.PUBLIC_URL + '/assets/img/ngapain.png'} alt="rocket" /> */}
        <img key="1" className="layer1" data-depth="-0.08" src={process.env.PUBLIC_URL + '/assets/img/layer1-new.png'} alt=""  />
       
        
        <img key="4" className="fgVincent" data-depth="0.1" src={process.env.PUBLIC_URL + '/assets/img/vincent.png'}  alt=""  />
        
        <img key="4" className="fgDesta" data-depth="0.1" src={process.env.PUBLIC_URL + '/assets/img/desta.png'}  alt=""  />
        <img key="2" className="fg1" data-depth="0.2" src={process.env.PUBLIC_URL + '/assets/img/foreground1.png'}  alt=""  />
        <img key="5" className="fgJasiman" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_jasiman.png'}  alt=""  />
        <img key="5" className="fgVindes" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes.png'}  alt=""  />
        <img key="5" className="fgNgapain" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/ngapain.png'}  alt=""  />
        <img key="5" className="fgC2" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_c2.png'}  alt=""  />
        <img key="5" className="fg2u" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_2u.png'}  alt=""  />
        <img key="5" className="fgMerch" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_merch.png'}  alt=""  />
        <img key="5" className="fgArt" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_art.png'}  alt=""  />
        <img key="5" className="fgLogo" data-depth="0" src={process.env.PUBLIC_URL + '/assets/img/fg_logo.png'}  alt=""  />
      </div>
      
     
    </div>

    <div className="latest-landing-sec parallax-mobile" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/bg_parallax.jpg'})`,
          }} >
      <div className="overlay-vincen"></div>
      <div className="overlay-desta"></div>


      <div id="scene-mobile"className="latest-landing-sec align-items-center">
        {/* <img key="1" className="vindes-box" data-depth="0.3" src={process.env.PUBLIC_URL + '/assets/img/ngapain.png'} alt="rocket" /> */}
        <img key="1" className="layer1" data-depth="-0.08" src={process.env.PUBLIC_URL + '/assets/img/layer1-new.png'} alt=""  />
       
        
        
        <img key="2" className="fg1" data-depth="0.2" src={process.env.PUBLIC_URL + '/assets/img/foreground1.png'}  alt=""  />
        <img key="4" className="fgVincent" data-depth="0.1" src={process.env.PUBLIC_URL + '/assets/img/vincent.png'}  alt=""  />
        
        <img key="4" className="fgDesta" data-depth="0.1" src={process.env.PUBLIC_URL + '/assets/img/desta.png'}  alt=""  />
        <img key="5" className="fgJasiman" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_jasiman.png'}  alt=""  />
        <img key="5" className="fgVindes" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes.png'}  alt=""  />
        <img key="5" className="fgNgapain" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/ngapain.png'}  alt=""  />
        <img key="5" className="fgC2" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_c2.png'}  alt=""  />
        <img key="5" className="fg2u" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_2u.png'}  alt=""  />
        <img key="5" className="fgMerch" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_merch.png'}  alt=""  />
        <img key="5" className="fgArt" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/fg_vindes_art.png'}  alt=""  />
        <img key="5" className="fgLogo" data-depth="0" src={process.env.PUBLIC_URL + '/assets/img/fg_logo.png'}  alt=""  />
      </div>
      
     
    </div>
      </>
    );
  }
}

export default translate(TesCom);
