import React from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import Logo from "../logo-vindes.png"
import ThankYouModal from "../Modals/ThankYouModal/ThankYouModal";
import { translate } from "react-multi-lang";
import Axios from "axios";
var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class RegisterComponent extends Helper {
  state = {
    data: {
      email: "",
      password: "",
      name: "",
      province: "",
      city: "",
      timezone: const_time_zone,
      referral_code: "",
    },
    loadingContent: null,
    buttonDisable: false,
    check_referral_response: "",
    provinces: [],
    cities: [],
    city:0,
    showModal: false,
  };

  async componentDidMount() {
    document.getElementById("footer").style.display = 'none'
    await window.scrollTo(0, 0);
    const query = new URLSearchParams(this.props.location.search);
    const referral = query.get("referral");
    if (referral) {
      this.setState({ data: { referral_code: referral } });
    }
    Axios.get('https://admin.vindes.id/userApi/list_provinces')
    .then( res => {
        let provinces = res.data.provinsi
        this.setState({ provinces: provinces });
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { state } = this.props.location;
    
    this.setState({
      loadingContent: this.props.t("button_loading"),
      buttonDisable: true,
    });
    api
      .postMethod("v4/register", this.state.data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userType", response.data.data.user_type);
          localStorage.setItem("push_status", response.data.data.push_status);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem(
            "active_profile_id",
            response.data.data.sub_profile_id
          );
          // ToastDemo(this.props.toastManager, response.data.message, "success");
          // this.props.history.push("/view-profiles");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            // showModal: true,
          });

          window.location = state ? state.from.pathname : "/";
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });

          if (response.data.error_code == 3001) {
            setTimeout(function() {
              window.location = "/login";
            }, 3000);
          }
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
        ToastDemo(this.props.toastManager, error, "error");
      });
  };

  responseFacebook = (response) => {
    const { path } = this.props.location;
    if (response) {
      if (response.status == "unknown") {
        ToastDemo(this.props.toastManager, "Cancelled", "error");
        return false;
      }
      const emailAddress =
        response.email === undefined || response.email === null
          ? response.id + "@facebook.com"
          : response.email;

      const facebookLoginInput = {
        social_unique_id: response.id,
        login_by: "facebook",
        email: emailAddress,
        name: response.name,
        device_type: "web",
        device_token: "123466",
        timezone: const_time_zone,
      };
      api
        .postMethod("v4/register", facebookLoginInput)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("push_status", response.data.data.push_status);
            localStorage.setItem("userType", response.data.data.user_type);

            localStorage.setItem("username", response.data.data.name);
            localStorage.setItem(
              "active_profile_id",
              response.data.data.sub_profile_id
            );
            // ToastDemo(
            //   this.props.toastManager,
            //   response.data.message,
            //   "success"
            // );
            // this.props.history.push("/view-profiles");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
              showModal:true,
            });
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch((error) => {
          ToastDemo(this.props.toastManager, error, "error");
          this.setState({ loadingContent: null, buttonDisable: false });
        });
    }
  };

  responseGoogle = (response) => {
    const path = this.props.location;
    if (response.profileObj) {
      const googleLoginInput = {
        social_unique_id: response.profileObj.googleId,
        login_by: "google",
        email: response.profileObj.email,
        name: response.profileObj.name,
        picture: response.profileObj.imageUrl,
        device_type: "web",
        device_token: "123466",
        timezone: const_time_zone,
      };
      api
        .postMethod("v4/register", googleLoginInput)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("userType", response.data.data.user_type);
            localStorage.setItem("push_status", response.data.data.push_status);
            localStorage.setItem("username", response.data.data.name);
            localStorage.setItem(
              "active_profile_id",
              response.data.data.sub_profile_id
            );
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            // this.props.history.push("/view-profiles");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
              showModal:true,
            });
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch((error) => {
          ToastDemo(this.props.toastManager, error, "error");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        });
    }
  };

  handleClose = (event) => {
    this.setState({
      showModal: event
    });
    this.props.history.push("/countdown");

  }

  checkReferralCode = (event) => {
    event.preventDefault();

    if (this.state.data.referral_code) {
      let inputData = {
        referral_code: this.state.data.referral_code,
      };
      api
        .postMethod("referral_code_validate", inputData)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loadingContent: null,
              buttonDisable: false,
              check_referral_response: response.data.message,
            });
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );

            // this.setState({
            //     check_referral_response: response.data.error
            // });
          }
        })
        .catch((error) => {
          ToastDemo(this.props.toastManager, error, "error");
        });
    }
  };

  selectProvince = (event) => {
    let province_id = event.target.value
    
    var index = event.nativeEvent.target.selectedIndex;
    let nama = event.nativeEvent.target[index].text

    // this.setState({provinceId : province_id });
    if( province_id > 0) {
      Axios.get('https://admin.vindes.id/userApi/list_cities?id_province='+ province_id)
      .then( res => {

          console.log(res.data.kota_kabupaten);
          let cities = res.data.kota_kabupaten
          this.setState(prevState => ({
            data: {                   // object that we want to update
                ...prevState.data,    // keep all other key-value pairs
                province: nama       // update the value of specific key
            }
        }))
          this.setState({ cities: cities });
          
      })
    }
    

  };
  selectCity = (event) => {
    let province_id = event.target.value
    
    var index = event.nativeEvent.target.selectedIndex;
    let nama = event.nativeEvent.target[index].text

    // this.setState({provinceId : province_id });
    
    this.setState(prevState => ({
        data: {                   // object that we want to update
            ...prevState.data,    // keep all other key-value pairs
            city: nama       // update the value of specific key
        }
    }))
    
    

  };
  render() {
    const { t } = this.props;
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    var formInput = {
      backgroundColor: 'white',
      height: '2.7em'
    };

    const { data } = this.state;
    return (
      <div>
        <ThankYouModal 
          show={this.state.showModal}
          onHide={this.handleClose}
          onClick={this.handleClose}

        />
        <div className="common-bg-img bg-signup" >
       
          <div className="row">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-7 auto-margin">
              <div className="register-box">
              <div className="auth-page-header register-header-first w-100 d-flex align-items-center justify-content-center flex-column">
                <Link to={"/"}>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/fg_logo.png'}
                    className="site-logo-vindes"
                    // style={{height: "25%"}}
                    alt="logo_img"
                  />
                </Link>
                   <div className="title-signup">
                   Registration
                   </div>
            </div>
                <form className="form-signup auth-form login-new-form title-register-form-first" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name"><b>Full Name</b></label>
                    <input
                      onChange={this.handleChange}
                      className="form-control no-border-input-register"
                      id="name"
                      name="name"
                      // placeholder="Name"
                      value={data.name}
                      style={formInput}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div className="form-group w-40" style={{width: '40%'}}>
                      <label><b>Province</b></label>
                      <select  className="form-control no-border-input-register" id="select-province" style={formInput} onChange={this.selectProvince}>                
                        <option value="0">Select Province</option>
                        {this.state.provinces.map( (item,index) => (
                          <option key={index} value={item.id}> {item.nama} </option>
                        ))}
                       
                      </select>
                    </div>
                    <div className="form-group" style={{width: '50%'}}>
                      <label><b>City</b></label>
                      <select  className="form-control no-border-input-register" id="exampleFormControlSelect1"  style={formInput}  onChange={this.selectCity}>
                      <option value="0" >Select City</option>
                        {this.state.cities.map( (item) => (
                          <option value={item.id}> {item.nama} </option>
                        ))}
                        
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email"><b>Email</b></label>
                    <input
                      type="email"
                      onChange={this.handleChange}
                      className="form-control no-border-input-register"
                      id="email"
                      name="email"
                      // placeholder="Email address"
                      value={data.email}
                      style={formInput}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd"><b>Password</b></label>
                    <input
                      type="password"
                      onChange={this.handleChange}
                      className="form-control no-border-input-register"
                      id="pwd"
                      name="password"
                      // placeholder="Password"
                      value={data.password}
                      style={formInput}
                    />
                  </div>
                  <div className="form-group">
                    <Link to="/termsandcondition">
                    <div className="register-privacy-police">
                    By clicking register, you agree to Our Terms of Service and Privacy Policy
                    </div>
                    </Link>
                  </div>

                  <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-danger register-btn-first"
                    disabled={this.state.buttonDisable}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Register"}
                  </button>
                  </div>

                </form>

                <div>
                  {configuration.get("configData.social_logins.FB_CLIENT_ID") ==
                  "" ? (
                    ""
                  ) : (
                    <FacebookLogin
                      appId={configuration.get(
                        "configData.social_logins.FB_CLIENT_ID"
                      )}
                      fields="name,email,picture"
                      scope="public_profile"
                      callback={this.responseFacebook}
                      render={(renderProps) => (
                        <button
                          className="social"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <i className="fab fa-facebook fb social-icons" />{" "}
                          {t("login_with")} {t("facebook")}
                        </button>
                      )}
                    />
                  )}
                </div>
                <div>
                  {configuration.get(
                    "configData.social_logins.GOOGLE_CLIENT_ID"
                  ) == "" ? (
                    ""
                  ) : (
                    <GoogleLogin
                      clientId={configuration.get(
                        "configData.social_logins.GOOGLE_CLIENT_ID"
                      )}
                      render={(renderProps) => (
                        <button
                          className="social"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <i className="fab fa-google-plus-square google social-icons" />{" "}
                          {t("login_with")} {t("google")}
                        </button>
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  )}
                </div>

                {/* <p className="auth-link">
                <span className="grey-text"> {t("already_have_account")}{" "}</span>
                  <Link to={"/login"} className="btn-link">
                    {t("sign_in_now")}
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(RegisterComponent));
