import React,{ Component } from "react";
import { Link } from "react-router-dom";
import StaticHeader from "../Layouts/SubLayout/StaticHeader";
import './TermsAndCondition.scss';

class TermsAndCondition extends Component {

render() {

return (
<section>
<StaticHeader></StaticHeader>
  <StaticHeader></StaticHeader>
  <div className="container-treamandcondition">
    <h1>
    Terms of Service and Privacy Policy
    </h1>
    <p><strong>VINDES</strong></p>

    <p><strong>Kebijakan &amp; Privasi</strong></p>

    <p><strong>Kami berusaha untuk mengutamakan kepentingan privasi dan menjaga kepercayaan Anda dengan melindungi
        informasi yang bersifat pribadi dan rahasia. Dengan mengunjungi dan mendaftarkan diri di website <a
          href="http://www.vindes.id">www.vindes.id</a> maka pengguna setuju untuk terikat syarat dan kondisi yang
        tercantum di website ini.<br />
        <br />
        Konten&nbsp;</strong></p>

    <p><strong>Beberapa halaman, atau layanan yang terdapat didalam situs <a
          href="http://www.vindes.id">www.vindes.id</a> memungkinkan Pengguna untuk mengirim konten yang dapat dilihat
        oleh pengguna maupun pengunjung lainnya. Konten yang Anda buat melalui situs kami dapat kami gunakan, seperti
        menyalin, menayangkan, mempertujungkan, mendistribusikan, dan juga mempromosikan konten tersebut di berbagai
        medium media sosial.</strong><br />
      &nbsp;</p>

    <p><strong>Disclaimer</strong></p>

    <p><strong>VINDES berhak sepenuhnya untuk memuat, tidak memuat, mengedit, dan/ atau menghapus data/ informasi yang
        disampaikan oleh pengguna dengan disesuaikan dengan nilai yang berlaku di <a
          href="http://www.vindes.id">www.vindes.id</a>.</strong></p>

    <p>&nbsp;</p>

    <p><strong>Lisensi Pengunjung Website</strong></p>

    <p><strong>Anda diperbolehkan untuk menggunakan situs <a href="http://www.vindes.id">www.vindes.id</a>, layanan, dan
        konten yang ditawarkan oleh kami hanya untuk keperluan pribadi, bukan untuk tujuan komersil. Anda tidak
        diperkenankan memproduksi ulang, mencetak, menyalin, mempublikasikan, menayangkan, menyebarkan, memodifikasi,
        menyimpan, menjual, meminjamkan, maupun mendistribusikan konten pada situs web <a
          href="http://www.vindes.id">www.vindes.id</a> tanpa seizin tertulis dari kami.</strong></p>

    <p>&nbsp;</p>

    <p><strong>Kebijakan Cookie</strong></p>

    <p><strong>Situs kami menggunakan cookie untuk dapat menyimpan berbagai informasi, seperti preferensi pribadi
        pengguna ataupun pengunjung ketika mengunjungi <a href="http://www.vindes.id">www.vindes.id</a>. Informasi yang
        diberikan cookie dapat membantu kami menganalisis profil dari pengunjung untuk memberikan pengalaman pengguna
        yang lebih baik. Pengguna juga dapat menggunakan cookie untuk mengingat password agar secara otomatis bisa log
        in pada situs kami.&nbsp;</strong></p>

    <p>&nbsp;</p>

    <p><strong>Kebijakan Alamat Surel</strong></p>

    <p><strong>Kami tidak akan pernah membagi, menjual, menyewakan, atau memberikan wewenang kepadah pihak ketiga atau
        manapun tanpa persetujuan dari Anda. Kami dapat mengirimkan e-mail berupa undangan untuk berpartisipasi dalam
        survey pengguna, tanggapan sebagai pengunjung atau pengguna, serta layanan yang ada atau sedang dikembangkan,
        serta informasi lainnya untuk mengetahui pengguna situs web kami dengan lebih baik (terdapat pilihan bagi
        pengguna yang tidak ingin menerima informasi ini).</strong></p>

    <p>&nbsp;</p>

    <p><strong>Hak Milik</strong></p>

    <p><strong>Seluruh rancangan yang dimiliki situs web <a href="http://www.vindes.id">www.vindes.id</a> seperti
        desain, gambar, audio, video, artwork, konten dalam situs web kami adalah hak cipta miliki kami. Anda tidak
        diperkenangkan untuk memodifikasi, mengubah, menambah, mengurangi, dan menyalin rancangan yang kami miliki dalam
        keadaan atau kondisi apapun.</strong></p>

    <p>&nbsp;</p>

    <p><strong>Hukum</strong></p>

    <p><strong>Syarat-syarat dan kondisi dalam kebijakan privasi ini tunduk kepada hukum yang berlaku di wilayah
        Republik Indonesia.</strong></p>

    <p><br />
      &nbsp;</p>

    <p><strong>Informasi Lainnya: Perubahan Privacy Policy</strong></p>

    <p><strong>Privacy Policy ini dapat diubah atau ditambah sewaktu-waktu. Setiap perubahan akan ditampilkan di halaman
        ini.</strong></p>
          <Link to="register">
          <div className="btn-back-treamandcondition">
            Back
          </div>
          </Link>

  </div>
</section>
);
}

}

// export default TermsAndCondition;
export default TermsAndCondition;
