import React, { Component } from "react";
import { Link } from "react-router-dom";
import './CountdownBanner.scss';

class CountdownBanner extends Component {
state = {
endDate: new Date("2021-08-16"),
days: 0,
hours: 0,
minutes: 0,
seconds: 0,
};

getTwoDigitValue = value => {
if (value < 10) { return "0" + value; } return "" + value; }; componentDidMount() { this.calculateCountdown() }
  calculateCountdown=()=> {
  const startDate = new Date();
  const { endDate } = this.state;
  let errorMsg = "";

  if (endDate < startDate) { errorMsg="Please select end date and time greater than current date and time." ; }
    this.setState({ errorMsg }); const timeRemaining=endDate.getTime() - startDate.getTime(); if (timeRemaining> 0) {
    const start_date = new Date(startDate);
    const end_date = new Date(endDate);
    const start_millis = start_date.getTime();
    const end_millis = end_date.getTime();
    const old_sec = start_millis / 1000;
    const current_sec = end_millis / 1000;
    let seconds = current_sec - old_sec;
    let days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * 24 * 60 * 60;
    let hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;
    let minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    days = Math.abs(days);
    hours = Math.abs(hours);
    minutes = Math.abs(minutes);
    seconds = Math.floor(Math.abs(seconds));

    this.setState(
    () => ({
    days,
    hours,
    minutes,
    seconds
    }),
    () => {
    this.timer = setTimeout(this.calculateCountdown, 1000);
    }
    );
    } else {
    clearTimeout(this.timer);
    }
    };

    render() {
    const imagesFooter = [
        {
          image: process.env.PUBLIC_URL + '/images/countdown/facebook.png',
          url: 'https://www.facebook.com/profile.php?id=100068859434989'
        },
        {
          image: process.env.PUBLIC_URL + '/images/countdown/instagram.png',
          url: 'https://www.instagram.com/vindes.ig/'
        },
        {
          image: process.env.PUBLIC_URL + '/images/countdown/tiktok.png',
          url: 'https://vt.tiktok.com/ZGJSM4P5o/'
        },
        {
          image: process.env.PUBLIC_URL + '/images/countdown/twitter.png',
          url: 'https://twitter.com/vindestwitt/'
        },
        {
          image: process.env.PUBLIC_URL + '/images/countdown/youtube.png',
          url: 'https://www.youtube.com/vindes'
        }
        
    ]
    const { days, hours, minutes, seconds } = this.state;
    const convertedDays = days;
    const convertedHours = this.getTwoDigitValue(hours);
    const convertedMins = this.getTwoDigitValue(minutes);
    const convertedSeconds = this.getTwoDigitValue(seconds);
    return (
    <section className="section-coundownbanner">
      <section className="container-content">
        <div className="section-count-down">
          <div>
          <div className="bg-a">{convertedDays}
          </div>
          <div className="bg-b">Hari</div>
          </div>
          <div>
          <div className="bg-a">{convertedHours}</div>
          <div className="bg-b">Jam</div>
          </div>
          <div>
          <div className="bg-a">{convertedMins}</div>
          <div className="bg-b">Menit</div>
          </div>
          <div>
          <div className="bg-a">{convertedSeconds}</div>
          <div className="bg-b">Detik</div>
          </div>
        </div>
        <div className="subtitle-date-content">
          <div className="section-bg-flex">
          <Link to="/register">
            <span>Klik di sini untuk jadi</span>
            <span className="right-content"> Vivin <span className="right-content-black">dan</span> Dedes!</span>
            </Link>
          </div>
        </div>
      </section>
      <footer className="footer-page-coutdown">
      <div className="section-footer-countdown">
      {imagesFooter.map((key, i) => (
      <img className="item-footer-countdown"  src={key.image} alt={key.url} onClick={() =>  window.open(key.url, '_blank', 'noopener,noreferrer')} />
      ))}
      </div>
      </footer>
    </section>
    )
    };
    }

    export default CountdownBanner;