import React, { useEffect, Component } from "react";

import {Link} from 'react-router-dom';
import Styled from "styled-components";
import Parallax from "parallax-js";
import PropTypes from "prop-types";





const Container = Styled.div`
	width: 100%;
	height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
	overflow: hidden;
	background: url(${process.env.PUBLIC_URL + '/assets/img/banner-vindes-lite.jpg'});
    background-repeat: no-repeat;
    background-size: cover;
`;

const ParallaxImagesContainer = ({ backgroundImage, layers }) => {
  useEffect(() => {
    const scene = document.getElementById("scene");
    new Parallax(scene);
  });

  return (
    <div className="latest-landing-sec align-items-center" backgroundImage={backgroundImage}>
      <div id="scene">
        <img key="1" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/vindesbox.png'} alt="rocket" />
        <img key="2" data-depth="0.5" src={process.env.PUBLIC_URL + '/assets/img/tv-vindes.png'} className="tv" alt=""  />
      </div>
    </div>
  );
};

ParallaxImagesContainer.propTypes = {
  backgroundImage: PropTypes.string,
  layers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      dataDepth: PropTypes.string
    })
  )
};

ParallaxImagesContainer.defaultProps = {
  
};

export default ParallaxImagesContainer;
