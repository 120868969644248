import React, { Component } from "react";
import UserHeader from "../Layouts/SubLayout/UserHeader";
import ArtikelModal from "../Modals/ArtikelModal/ArtikelModal";
import api from "../../Environment";
import "./Artikel.scss";
import ButtonFloatingArt from "../Karya/ButtonFloatingArt";
let userId = localStorage.getItem("userId");
let accessToken = localStorage.getItem("accessToken");

class Artikel extends Component {
  state = {
    showModalDetailArtikel: false,
    dataPropsDetailModalArtikel: [],
    categoryArtikel: [],
    page: 1,
    isActiveCategory: "All content",
    listArtikel: [],
    featureArtikel:{},
    loading: true,
    errorHandle: 0,
  };

  goDetailModalArtikel = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailArtikel(self.id);
  }

  onHideModalArtikel() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ showModalDetailArtikel: false });
  }

  async fecthApiArtikel(category) {
   
    await api
      .postMethod("articles/list", {page: this.state.page})
      .then(async (response) => {
        if (response.data.success === true) {


          this.setState({ 
            loading: false, 
            listArtikel: [...this.state.listArtikel, ...response.data.data.articles],
            featureArtikel: response.data.data.main_article[0]
          })
          // console.log(response.data.data.main_article);

        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  async filterArtikel(category) {
    this.setState({page: 1})
    await api
      .postMethod("tag/articles", {tag_id: this.state.isActiveCategory, page: this.state.page})
      .then(async (response) => {
        if (response.data.success === true) {


          this.setState({ 
            loading: false, 
            listArtikel: response.data.data.articles
          })
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  renderListArtikel = (listDataSelf, index) => {
    return (
    <React.Fragment key={index}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 master" style={{paddingLeft: index % 2 === 0 ? '2vw' : '9vw'}}>
        <div className="section-list-artikel">
          {listDataSelf.thumbnail_image ?
          <div
          className="artikel-list-thum"
          style={{
            backgroundImage: `url(${listDataSelf.thumbnail_image})`,
          }}
          /> 
          :<div
          className="artikel-list-thum"
          style={{
            backgroundImage: `url(${listDataSelf.image})`,
          }}
          />}
          
          <div className="artikel-list-content">
            {listDataSelf.thumbnail_image?
            <div
            className="artikel-list-thum-mobile"
            style={{
              backgroundImage: `url(${listDataSelf.thumbnail_image})`,
            }}
            /> 
            :<div
            className="artikel-list-thum-mobile"
            style={{
              backgroundImage: `url(${listDataSelf.image})`,
            }}
            /> }
          </div>
        </div>
          <div className="artikel-list-cat">
            {listDataSelf.tags.length > 0 ? listDataSelf.tags.map((tag) => tag.name).join(", ") : "No Tag"}
          </div>
          <div className="artikel-list-title">{listDataSelf.title}</div>
          <div className="artikel-list-desc">{listDataSelf.creator} - {listDataSelf.date}</div>
          <button id={"read-more-"+listDataSelf.title} className="btn badge-artikel-readmore" 
              onClick={() => this.goDetailModalArtikel(listDataSelf)}>Read more</button>     
      </div>
    </React.Fragment>
    )
  }

  async componentDidMount() {
    await window.scrollTo(0, 0);
    document.getElementById("footer").style.display = await 'block'
    await this.fecthApiArtikel("");
    this.showDetailLink()
    this.sharedLinkAction()
    this.getCategoryList()
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
        this.setState({page: this.state.page+1})
        this.fecthApiArtikel()
      }
    }

  sharedLinkAction(){
    document.getElementById("header").style.zIndex = 1000;
    if (this.props.match.params.id !== null){
      this.getDetailArtikel(this.props.match.params.id );
    }
    
  }

  async getDetailArtikel(article_id) {
    api.postMethod("article/detail", { id: article_id}).then((response) => {
      if(response.data.success) {
        this.setState({
          showModalDetailArtikel: true,
          dataPropsDetailModalArtikel: response.data.data
        })
      }
    });
    
  }

  async getCategoryList(){
    api.postMethod("articles/list", {page: this.state.page}).then((response) => {
      this.setState({categoryArtikel: response.data.data.tags})
    })
  }

  showDetailLink = ()=>{
    if(this.props.location.article_id){
      this.getDetailArtikel(this.props.location.article_id)
    }
  }

  async switchIsActiveCategory(event) {
    await this.setState({
      isActiveCategory: event,
    });
    await this.filterArtikel(event);
  }

  render() {
    const {
      isActiveCategory,
      showModalDetailArtikel,
      dataPropsDetailModalArtikel,
      listArtikel,
      loading,
      featureArtikel,
    } = this.state;

    const likeArticle = (article_id) => {
      if(userId && accessToken && localStorage.getItem("userId") !== 58 && localStorage.getItem("accessToken") !== '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi') {
        api.postMethod("article/like", { user_id:userId, article_id:article_id}).then((response) => {
          if(response.data.data) {
            this.setState({
              dataPropsDetailModalArtikel: response.data.data
            });
          }
        });
      }else {
        this.props.history.push("/login");
      }
    }

    return (
      <>
        <UserHeader activeNav="ARTIKEL" />        
        
        <section className="section-wrapper-artikel">
        <section className="filter-container-creation-section-wrapper">
            <div className="d-flex filter-container-creation-section">
              <div className="mr-auto">
              <div className="d-flex">
                  {this.state.categoryArtikel.map((key, index) => (
                    <div
                      key={`${index}`}
                      className={
                        isActiveCategory === key.id
                          ? "btn badge-creation-section-active"
                          : "btn badge-creation-section"
                      }
                      onClick={() => this.switchIsActiveCategory(key.id)}
                    >
                      {key.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <div className="feature-artikel" onClick={() => this.goDetailModalArtikel(featureArtikel)}>
            <div className="row">
              <div className="col-md-6 nopadding">
                <div className="feature-artikel-title">{featureArtikel.title}</div>
              </div>
              <div className="col-md-6 feature-artikel-thum">
                <img src={`${featureArtikel.thumbnail_image}`} alt=""/>
              </div>
            </div>
          </div>
          <div className="feature-artikel-mobile" onClick={() => this.goDetailModalArtikel(featureArtikel)}>
            <div className="feature-artikel-mobile-content">
              {featureArtikel.thumbnail_image?
              <div
              className="feature-artikel-mobile-thum"
              style={{
                backgroundImage: `url(${featureArtikel.thumbnail_image})`,
              }}
              /> 
              :<div
              className="feature-artikel-mobile-thum"
              style={{
                backgroundImage: `url(${featureArtikel.image})`,
              }}
              /> }
            </div>
            <div className="feature-artikel-mobile-title">{featureArtikel.title}</div>
            <div className="feature-artikel-mobile-published">{featureArtikel.creator} - {featureArtikel.date}</div>
          </div>
          <div class="border-artikel"></div>
          <div className="row">
            {loading
              ? ""
              : listArtikel.map((listData, index) =>
                  listData.length === 0 ? "" : this.renderListArtikel(listData, index)
                )}
          </div>
        </section>
        <ButtonFloatingArt link="/submit-artikel" name="ADD ARTIKEL"></ButtonFloatingArt>
        <div className="gap-padding-bottom" />
        <ArtikelModal
          show={showModalDetailArtikel}
          dataPropsDetailModalArtikel={dataPropsDetailModalArtikel}
          onHide={() => this.onHideModalArtikel()}
          likeArticle = {likeArticle}
        />
      </>
    );
  }
}

export default Artikel;
