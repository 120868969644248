import React, { Component } from "react";
import "./Audio.scoped.css";

import api from "../../Environment";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import UserHeader from "../Layouts/SubLayout/UserHeader";
import AOS from "aos";
import "aos/dist/aos.css";
import AudioModal from "../Modals/AudioModal/AudioModal";
let userId = localStorage.getItem("userId");
let accessToken = localStorage.getItem("accessToken");



class Audio extends Component {
  state = {
    showNavbar: false,
    listAudio: [],
    dataPropsDetailModalAudio:null,
    page: 1
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      body: "",
      data: [],
    };
  }

  handleClose = (fromModal) => {
    this.setState({
      show: false,
    });
  };

  loadMore = (event) => {
    api.postMethod("audio/list", { page: this.state.page+1}).then((response) => {
      if (response.data.success) {
        this.setState({
          listAudio: [...this.state.listAudio,...response.data.data],
          page: this.state.page+1
        });
      }
    });
  };

  async getAudio(event) {
    api.postMethod("audio/list", { page: this.state.page}).then((response) => {
      if (response.data.success) {
        this.setState({
          listAudio: response.data.data
        });
      }
    });
  }

  async componentDidMount() {
    await this.setState({page:1})
    await window.scrollTo(0, 0);
    document.getElementById("footer").style.display = await 'block'
    window.addEventListener('scroll', this.handleScroll, true);
    this.getAudio();
    await AOS.init();
    this.showDetailLink();
    this.sharedLinkAction();
  }

  async componentDidUpdate() {
    await AOS.refresh();
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
        this.loadMore()
      }
    }

  showDetailLink = ()=>{
    if(this.props.location.audio_id){
      this.getDetailAudio(this.props.location.audio_id)
    }
  }

  sharedLinkAction(){
    document.getElementById("header").style.zIndex = 1000;
    if (this.props.match.params.id !== null){
      this.getDetailAudio(this.props.match.params.id );
    }
  }

  async getDetailAudio(audio_id) {
    api.postMethod("audio/detail", { id: audio_id}).then((response) => {
      if(response.data.success) {
        this.setState({
          show: true,
          dataPropsDetailModalAudio: response.data.data
        })
      }
    });
  }

  goDetailModalAudio = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailAudio(self.id);
  }

  onHideModalArt() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ show: false });
  }

  prefixParentChangeDetailModalArt = (self) => {
    document.getElementById("header").style.zIndex = 1000;
    this.getDetailAudio(self.id);
  }

  render() {
    const {
      showNavbar,
      dataPropsDetailModalAudio
    } = this.state;

    const likeAudio = (audio_id) => {
      if(userId && accessToken && localStorage.getItem("userId") !== 58 && localStorage.getItem("accessToken") !== '2y10xHd6SXzBZ7dbmeCl82I5ugA5zRHF1up9Zq56CSrTIAvo2bjDi') {
        api.postMethod("audio/like", { user_id:userId, audio_id:audio_id}).then((response) => {
          if(response.data.data) {
            this.setState({
              dataPropsDetailModalAudio: response.data.data
            });
          }
        });
      }else {
        this.props.history.push("/login");
      }
    }

    return (
      <>
        <UserHeader activeNav="AUDIO" showNavbar={showNavbar} />
        <div className="section-wrapper-audio-page">
          <div className="section-wrapper-audio">
            <AudioModal
              show={this.state.show}
              data={dataPropsDetailModalAudio}
              onHide={() => this.onHideModalArt()}
              likeAudio = {likeAudio}
              prefixParentChangeDetailModalArt={this.prefixParentChangeDetailModalArt}
            >
            </AudioModal>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry>
                {this.state.listAudio &&
                  this.state.listAudio.map((audio, i) => (
                    <div
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      className="gallery-item-container"
                      key={i}
                      onClick={() => this.goDetailModalAudio(audio)}
                      id={audio.slug}
                    >
                      <div className="gallery-item-wrapper">
                        <div>
                          <div className=" overlay-audio align-items-center justify-content-center">
                            <i className="fa fa-music audio-icon"></i>
                          </div>
                          <img
                          className="gallery-item"
                          key={i}
                          src={audio.thumbnail_image}
                          style={{ width: "100%", display: "block" }}
                          alt=""
                          id={audio.slug}
                          />
                        </div>
                      </div>
                      
                      <div className="section-name-audio">
                        {audio.name}
                      </div>
                      <div className="section-title-audio">
                        {audio.title}
                      </div>
                    </div>
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
        <div className="gap-padding-bottom" />
      </>
    );
  }
}

export default Audio;
