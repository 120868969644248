import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import './ContentEventModal.scss';
import {FacebookShareButton, TwitterShareButton,  TwitterIcon,   WhatsappShareButton, WhatsappIcon, FacebookIcon,} from "react-share";

class ContentPromoC2Modal extends Component {

  state = {
    isCopy: false,
  }
  
  copyLinkPromo(link) {
    navigator.clipboard.writeText((window.location.href+"/"+link).replace('promoc2//','promoc2/'));
    this.setState({ isCopy: true });
    setTimeout(() => {
      this.setState({ isCopy: false });
    }, 1000);
  }

  async openInstagram(link){
    await this.copyLinkPromo(link)
    var win = window.open('instagram://story-camera');
  }

  
  async openLinkInstagram(link){

    if(!link.includes('http') ){
     
      link = 'https://' + link
    }

    var win = window.open(link);
  }
    render(props) {
      const { isCopy } = this.state;
      const dataProps = this.props.dataPropsDetailEvent
   

        return (
            <Modal className="modal-promoc2-wrapper" show={this.props.show}
            onHide={() => this.props.onHide(false)}>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                {this.props.dataPropsDetailEvent ? (
            <section className="wrapper-promoc2">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="wrapper-image-promoc2">
                      <img
                        src={this.props.dataPropsDetailEvent.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                        }}
                        alt={this.props.dataPropsDetailEvent.event_name}
                      />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-conten-promoc2">
                    <div className="title-wrapper-promoc2">
                      {this.props.dataPropsDetailEvent.event_name}
                    </div>

                    <div className="location-wrapper-promoc2">

                      {this.props.dataPropsDetailEvent.event_date_end ? 
                      <div className="title-small"><span className="fas fa-calendar icon"></span>{this.props.dataPropsDetailEvent.event_date} - {this.props.dataPropsDetailEvent.event_date_end}</div> 
                      : 
                      <div className="title-small"><span className="fas fa-calendar icon"></span>{this.props.dataPropsDetailEvent.event_date}</div>
                      }
                      
                      <div className="title-small"><span className="fas fa-map-marker-alt icon"></span>{this.props.dataPropsDetailEvent.location}</div>                    
                      
                      <div className="title-small-do">Diselenggarakan oleh</div>
                      <div className="title-small-organizer">{this.props.dataPropsDetailEvent.event_organizer}</div>
                    </div>

                    <div className="desc-wrapper-promoc2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.dataPropsDetailEvent
                            .description,
                        }}
                      />
                    </div>

                    <div className="more-desc-wrapper-promoc2">
                      <div className="more-desc-title">More Info</div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.dataPropsDetailEvent
                            .more_description,
                        }}
                      />
                    </div>
                 
                    
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {isCopy && <div className="modal-copied">Link Copied</div>}
                </Modal.Body>
            </Modal>
        )
    };
}

export default ContentPromoC2Modal;