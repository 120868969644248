import React, { Component } from "react";
import api from "../../Environment";
import UserHeader from "../Layouts/SubLayout/UserHeader";
import renderHTML from "react-render-html";
import { t } from "react-multi-lang";
import "./ListEvent.scss";
import ContentEventModal from "../Modals/ContentEventModal/ContentEventModal";

class ListEvent extends Component {
  state = {
    data: {},
    loading: true,
    type_event: null,
    showModaldetailEvent: false,
    dataPropsDetailEvent: [],
  };

  async getDetailEvent(id) {

    api.postMethod("events/detail", { id: id}).then((response) => {
      if(response.data.success) {
        this.setState({
          showModaldetailEvent: true,
          dataPropsDetailEvent: response.data.data
        })
      }
    });
    
  }

  detailEvent(params) {
    document.getElementById("header").style.zIndex = 1;
    this.setState({
      showModaldetailEvent: true,
      dataPropsDetailEvent: params,
    });
  }

  onHideModalEvent() {
    document.getElementById("header").style.zIndex = 999999;
    this.setState({ showModaldetailEvent: false });
  }

  renderListEvent = (listDataSelf, index) => {
    return (
    <React.Fragment key={index}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 events" onClick={() => this.detailEvent(listDataSelf)}>
        <div className="section-list-event">
          <div
            className="item-list-event-thum"
            style={{
              backgroundImage: `url(${listDataSelf.image})`,
            }}
          />
        </div>
        <div className="section-content">
          <div className="item-list-title">{listDataSelf.event_name}</div>
          <div className="item-list-title-small"><span className="fas fa-calendar icon"></span>{listDataSelf.event_date}</div>
          <div className="item-list-title-small"><span className="fas fa-map-marker-alt icon"></span>{listDataSelf.location}</div>
        </div>
        <div className="section-footer">
          <div className="item-list-title">{listDataSelf.event_organizer}</div>
        </div>
      </div>
    </React.Fragment>
    )
  }

  componentDidMount() {
    this.singlePageAPICall(this.props.match.params.id);
  }

  singlePageAPICall = type_event => {
    api.postMethod("events/list_detail", {type_event: type_event})
    .then(response => {
      if (response.data.success === true) {
          console.log(response.data.data);
            this.setState({
                loading: false,
                data: response.data.data,
                type_event:type_event
            });
      }
    });
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
    this.singlePageAPICall(nextProps.location.state.type_event);
  }

  render() {
    const { 
      loading, 
      data, 
      type_event, 
      showModaldetailEvent,
      dataPropsDetailEvent, 
    } = this.state;

    return (
        <>
        <UserHeader activeNav="EVENT" />
        <section className="section-wrapper-events">
            <h3 className="mt-5 title">{this.state.type_event} Events</h3>
            <div className="row">
            {loading
                ? ""
                : data.map((listData, index) =>
                    listData.length === 0 ? "" : this.renderListEvent(listData, index)
                )}
            </div>
        </section>
        <div className="gap-padding-bottom" />
        <ContentEventModal
          show={showModaldetailEvent}
          dataPropsDetailEvent={dataPropsDetailEvent}
          onHide={() => this.onHideModalEvent()}
        />
        </>
    );
  }
}

export default ListEvent;