import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./AudioModal.scss";
import { FacebookShareButton, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, FacebookIcon, } from "react-share";

class AudioModal extends Component {
  state = {
    isCopy: false,
  };

  generateLinkAudio(link_audio) {
    if(link_audio && link_audio.includes('spotify')) {
      if (link_audio && link_audio.includes('track')) {
        return link_audio.replace('/track/', '/embed/track/');
      } else if (link_audio && link_audio.includes('episode')) {
        return link_audio.replace('/episode/', '/embed/episode/');
      } else{
        return link_audio
      }
    }
  }

  copyLinkAudio(link) {
    navigator.clipboard.writeText((window.location.href+"/"+link).replace('audio//','audio/'));
    this.setState({ isCopy: true });
    setTimeout(() => {
      this.setState({ isCopy: false });
    }, 1000);
  }

  async openInstagram(link){
    await this.copyLinkAudio(link)
    var win = window.open('instagram://story-camera');
  }

  render(props) {
    const { isCopy } = this.state;
    return (
      <Modal
        className="modal-storeprefix-wrapper"
        show={this.props.show}
        onHide={() => this.props.onHide(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {this.props.data ? (
            <section className="wrapper-storeprefix">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-image-storeprefix">
                    <iframe
                      className="wrapper-audio-art"
                      src={this.generateLinkAudio(this.props.data.link_audio)}
                      scrolling="no"
                      frameborder="no"
                      allow="autoplay"
                      alt={this.props.data.name}
                      title={this.props.data.name}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="wrapper-conten-storeprefix">
                    <div className="title-wrapper-storeprefix">
                      {this.props.data.title}
                    </div>

                    <div className="wrapper-flex-storeprefix-button-text">
                      <div className="item-desktop">
                        <div className="d-flex justify-item-between">
                          <div className="date-art-button mr-3">
                            {this.props.data.created_at}
                          </div>
                          <WhatsappShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('audio//', 'audio/')}
                            quote={this.props.data.title + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <WhatsappIcon size={40} round={true} />
                            </a>
                          </WhatsappShareButton>
                          <FacebookShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('audio//', 'audio/')}
                            quote={this.props.data.title + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <FacebookIcon size={40} round={true} />
                            </a>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('audio//', 'audio/')}
                            quote={this.props.data.title + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <TwitterIcon size={40} round={true} />
                            </a>
                          </TwitterShareButton>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.openInstagram(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                            </div>
                          </div>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.copyLinkAudio(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                            </div>
                          </div>
                          
                          <div  className={`wrapper-button-art  ${this.props.data.is_like ? "art-liked": ""}`} onClick={() => this.props.likeAudio(this.props.data.id)}>
                            <div className="container-button-art">
                              <i className="fa fa-heart child-icon-buton-art" />
                              <div className="child-text-button-art">{this.props.data.like}</div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="item-mobile ">
                        <div className="d-flex">
                          <div className="date-art-button mr-3">
                            {this.props.data.created_at}
                          </div>
                          <WhatsappShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('audio//', 'audio/')}
                            quote={this.props.data.title + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <WhatsappIcon size={30} round={true} />
                            </a>
                          </WhatsappShareButton>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.openInstagram(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/1024px-Instagram_icon.png'} />
                            </div>
                          </div>
                          <FacebookShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('audio//', 'audio/')}
                            quote={this.props.data.title + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <FacebookIcon size={30} round={true} />
                            </a>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={(window.location.href + '/' + this.props.data.id).replace('audio//', 'audio/')}
                            quote={this.props.data.title + " - " + (this.props.data.description && this.props.data.description.replace(/(<([^>]+)>)/gi, ""))}
                            hashtag="#VinDes"
                            className=" mr-2"
                          >
                            <a href="#" className="">
                              <TwitterIcon size={30} round={true} />
                            </a>
                          </TwitterShareButton>
                          <div
                            className="wrapper-button-art-share mr-2"
                            onClick={() => this.copyLinkAudio(this.props.data.id)}
                          >
                            <div className="container-button-art">
                              <img src={process.env.PUBLIC_URL + '/assets/img/copy.png'} />
                            </div>
                          </div>
                          <div  className={`wrapper-button-art  ${this.props.data.is_like ? "art-liked": ""}`} onClick={() => this.props.likeAudio(this.props.data.id)}>
                            <div className="container-button-art">
                              <i className="fa fa-heart child-icon-buton-art" />
                              <div className="child-text-button-art">{this.props.data.like}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="desc-wrapper-storeprefix">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.data.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {isCopy && <div className="modal-copied">Link Copied</div>}
        </Modal.Body>
      </Modal>
    );
  }
}

export default AudioModal;
